<div
	#scrollContainer
	class="accordion-container"
	[ngClass]="{
		'accordion-container-small':
			!viewModel ||
			((!viewModel.groupDisplayOrder || !viewModel.groupDisplayOrder.length || viewModel.groupDisplayOrder.length === 1) &&
				(!viewModel.groupData || !viewModel.groupData.balances)),
		'accordion-container-min-height': !hideTable,
	}">
	<mat-accordion #accordion #contentAccordion="matAccordion" multi displayMode="flat" [hidden]="hideTable">
		<!-- scrollable date header -->
		<mat-expansion-panel #expansionPanel class="control-panel" [expanded]="false" [disabled]="true">
			<mat-expansion-panel-header class="recon-data-header" [ngClass]="{ 'cell-bottom-border': expansionPanel.expanded }">
				<div *ngIf="refreshingData" class="loading">
					<trovata-loading-icon [size]="1"></trovata-loading-icon>
				</div>
				<div class="w-100 flex flex-ai-c flex-row" mainRow>
					<div
						class="scrollable-group-left-column flex flex-row flex-jc-fe"
						[style.width]="sanitizer.bypassSecurityTrustStyle('calc(' + 100.0 / (viewModel.dateArray.length + 1) + '% - 36px)')">
						<button
							class="mat-icon-button"
							mat-icon-button
							data-html2canvas-ignore="true"
							(click)="scrollLeft()"
							aria-label="Scroll Left"
							matTooltip="Scroll Left"
							matTooltipShowDelay="700"
							[disabled]="mainScroller.scrollLeft === 0"
							*ngIf="viewModel.dateArray.length * itemWidth >= mainContainer.offsetWidth">
							<mat-icon>chevron_left</mat-icon>
						</button>
					</div>
					<div
						class="header-alignment-area flex flex-row flex-ai-c cell-right-border cell-left-border"
						#mainContainer
						[style.width]="sanitizer.bypassSecurityTrustStyle('calc(' + (100.0 - 100.0 / (viewModel.dateArray.length + 1)) + '% - 56px)')">
						<div #mainScroller id="recon-date-scroller" class="viewport date-scroller w-100" (scroll)="onDateScroll()" tabindex="0">
							<div
								*ngFor="let date of viewModel.dateLabels?.length ? viewModel.dateLabels : viewModel.dateArray; let idx = index"
								class="date-text bold flex-item"
								[style.minWidth.px]="itemWidth - 24"
								[style]="viewModel.columnStyles[date]"
								[ngClass]="{ 'cell-right-border': viewModel.dateArray && (idx !== viewModel.dateArray.length - 1 || viewModel.summaryColumn) }">
								{{ date }}
							</div>
							<div *ngIf="viewModel.summaryColumn" class="date-text bold flex-item" [style.minWidth.px]="itemWidth - 24">
								{{ viewModel.summaryColumn.displayValue }}
							</div>
						</div>
					</div>
					<div class="scrollable-group-right-column">
						<button
							class="mat-icon-button"
							mat-icon-button
							data-html2canvas-ignore="true"
							(click)="scrollRight()"
							aria-label="Scroll Right"
							matTooltip="Scroll Right"
							matTooltipShowDelay="700"
							[disabled]="mainScroller.scrollLeft === mainScroller.scrollWidth - mainScroller.offsetWidth"
							*ngIf="viewModel.dateArray.length * itemWidth >= mainContainer.offsetWidth">
							<mat-icon>chevron_right</mat-icon>
						</button>
					</div>
				</div>
			</mat-expansion-panel-header>
		</mat-expansion-panel>

		<!-- group expansion panels -->
		<div
			cdkDropList
			class="drag-drop-container"
			(cdkDropListDropped)="drop($event)"
			[cdkDropListDisabled]="!viewModel.groupData.sortable || !sortable"
			[style.height]="dataRowContainerHeight">
			<ng-container *ngIf="viewModel.groupDisplayOrder.length < 20 || disableVirtualScroll; then noVirtualScroll; else virtualScroll"> </ng-container>
			<ng-template #noVirtualScroll>
				<div
					*ngFor="let groupId of viewModel.groupDisplayOrder; let idx = index"
					cdkDrag
					[cdkDragDisabled]="!viewModel.groupData.groups[groupId].sortable"
					[cdkDragPreviewClass]="['cell-bottom-border', 'cell-top-border', 'cell-left-border', 'cell-right-border', 'scrollable-data-table-dragging']"
					[ngClass]="{
						'cell-bottom-border': viewModel.groupDisplayOrder && idx !== viewModel.groupDisplayOrder.length - 1,
						'cursor-grab': viewModel.groupData.groups[groupId].sortable && sortable,
					}">
					<ng-container [ngTemplateOutlet]="groupContainer" [ngTemplateOutletContext]="{ groupId: groupId, groupIndex: idx }"> </ng-container>
				</div>
			</ng-template>
			<ng-template #virtualScroll>
				<div
					*uiScroll="let groupId of groupIDataSource; let idx = index"
					cdkDrag
					[cdkDragDisabled]="!viewModel.groupData.groups[groupId].sortable"
					[cdkDragPreviewClass]="['cell-bottom-border', 'cell-top-border', 'cell-left-border', 'cell-right-border', 'scrollable-data-table-dragging']"
					[ngClass]="{
						'cell-bottom-border': viewModel.groupDisplayOrder && idx !== viewModel.groupDisplayOrder.length - 1,
						'cursor-grab': viewModel.groupData.groups[groupId].sortable && sortable,
					}">
					<ng-container [ngTemplateOutlet]="groupContainer" [ngTemplateOutletContext]="{ groupId: groupId, groupIndex: idx }"> </ng-container>
				</div>
			</ng-template>
		</div>

		<ng-template #groupContainer let-groupId="groupId" let-groupIndex="groupIndex">
			<app-scrollable-group-container
				[group]="viewModel.groupData.groups[groupId]"
				[dateArray]="viewModel.dateArray"
				[summaryColumn]="viewModel.summaryColumn"
				[disableMenus]="disableMenus"
				[columnStyles]="viewModel.columnStyles"
				[sortable]="sortable"
				[width]="mainScroller.offsetWidth"
				[scrollLeft]="scrollLeftValue"
				[itemWidth]="itemWidth"
				[droppedItem]="droppedItem"
				[clickableHeader]="clickableHeader"
				[clickableLabel]="clickableLabel"
				(toggleCheck)="toggleCheck()"
				[editable]="editable"
				(customMenuClicked)="onCustomMenuClicked($event)"
				(sortedGroup)="sortedGroup.emit($event)"
				(itemClicked)="itemClicked.emit($event)"
				(altChildrenIconClicked)="onAltChildrenIconClick($event)"
				(groupOptionSelected)="onGroupOptionSelected($event, groupIndex)"
				[disableExpandProperties]="disableExpandProperties">
				<div
					class="color-code"
					*ngIf="viewModel.groupData.groups[groupId].color && !viewModel.groupData.groups[groupId].icon"
					[ngStyle]="{ 'background-color': viewModel.groupData.groups[groupId].color }"
					cdkDragHandle
					[ngClass]="{ 'cursor-grab': viewModel.groupData.sortable && sortable }"></div>
				<span
					class="icon-badge"
					*ngIf="viewModel.groupData.groups[groupId].icon"
					[ngClass]="{
						'forecast-data-view-icon': viewModel.groupData.groups[groupId].icon === 'F',
						'stream-data-view-icon': viewModel.groupData.groups[groupId].icon === 'S',
						'group-data-view-icon': viewModel.groupData.groups[groupId].icon === 'G',
					}">
					{{ viewModel.groupData.groups[groupId].icon }}
				</span>
			</app-scrollable-group-container>
		</ng-template>

		<div class="totals-panels cell-top-border" *ngIf="viewModel.groupData.totals || viewModel.groupData.balances">
			<mat-expansion-panel
				#expansionPanel
				*ngIf="viewModel.groupData.totals"
				[ngClass]="{ 'cell-bottom-border': viewModel.groupData.balances }"
				[expanded]="viewModel.groupData.totals.expanded"
				[disabled]="true">
				<mat-expansion-panel-header class="recon-data-header" [ngClass]="{ 'cell-bottom-border': expansionPanel.expanded }">
					<div class="flex w-100">
						<div
							class="title-item alt-child-icon-button"
							[style.width]="sanitizer.bypassSecurityTrustStyle('calc(' + 100.0 / (viewModel.dateArray.length + 1) + '% - 36px)')">
							<button
								class="mat-icon-button"
								mat-icon-button
								data-html2canvas-ignore="true"
								matTooltipShowDelay="700"
								(click)="onAltChildrenIconClick(viewModel.groupData.totals); $event.stopPropagation()"
								*ngIf="viewModel.groupData.totals.altChildren && viewModel.groupData.totals.altChildren.length">
								<mat-icon [ngClass]="{ 'trovata-blue-text': viewModel.groupData.totals.altChildrenExpanded }">{{
									viewModel.groupData.totals.altChildrenExpanded ? 'schedule' : 'history_toggle_off'
								}}</mat-icon>
							</button>
							<button
								class="mat-icon-button"
								mat-icon-button
								data-html2canvas-ignore="true"
								matTooltipShowDelay="700"
								(click)="toggleTotalsExpand(); $event.stopPropagation()"
								*ngIf="!disableExpandTotals && !disableExpandProperties">
								<mat-icon *ngIf="!expansionPanel.expanded" class="child-expansion-button">arrow_right</mat-icon>
								<mat-icon *ngIf="expansionPanel.expanded" class="child-expansion-button">arrow_drop_down</mat-icon>
							</button>
							<div
								class="title-text bold"
								[style.marginLeft.px]="!disableExpandTotals && !disableExpandProperties ? 0 : 40"
								[ngClass]="{ 'clickable-value': clickableLabel && !viewModel.groupData.totals.disableGroupLabelClick, 'ml-40': disableExpandTotals }"
								(click)="
									onClick(
										clickableLabel && !viewModel.groupData.totals.disableGroupLabelClick,
										viewModel.groupData.totals,
										null,
										null,
										true,
										$event,
										expansionPanel
									)
								">
								{{ viewModel.groupData.totals.groupInfo }}
							</div>
							<ng-container *ngFor="let marker of viewModel.groupData.totals.headerMarkers">
								<span class="header-marker" [class]="marker.class" [matTooltip]="marker.tooltip">
									<mat-icon *ngIf="marker.icon" [class]="marker.iconClass">{{ marker.icon }}</mat-icon>
									<span *ngIf="marker.text" class="marker-text">{{ marker.text }}</span>
								</span>
							</ng-container>
						</div>
						<div
							class="header-alignment-area cell-right-border cell-left-border"
							[style.width]="sanitizer.bypassSecurityTrustStyle('calc(' + (100.0 - 100.0 / (viewModel.dateArray.length + 1)) + '% - 56px)')">
							<div #scroller class="viewport w-100" [style.width.px]="mainScroller.offsetWidth">
								<ng-container *ngFor="let date of viewModel.dateArray; let idx = index">
									<ng-template
										[ngTemplateOutlet]="dataCell"
										[ngTemplateOutletContext]="{ idx: idx, dataAccessor: date, groupData: viewModel.groupData.totals, isSummaryCol: false }"></ng-template>
								</ng-container>
								<ng-container *ngIf="viewModel.summaryColumn">
									<ng-template
										[ngTemplateOutlet]="dataCell"
										[ngTemplateOutletContext]="{
											idx: -1,
											dataAccessor: viewModel.summaryColumn.dataKey,
											groupData: viewModel.groupData.totals,
											isSummaryCol: true,
										}"></ng-template>
								</ng-container>
							</div>
						</div>
						<div class="total-panel-menu-container">
							<button
								class="mat-icon-button"
								mat-icon-button
								data-html2canvas-ignore="true"
								matTooltipShowDelay="700"
								[disabled]="disableMenus"
								*ngIf="viewModel.groupData.balances?.customMenuOptions">
								<mat-icon>more_vert</mat-icon>
							</button>
						</div>
					</div>
				</mat-expansion-panel-header>
				<div class="child-rows-background">
					<app-scrollable-property-container
						[group]="viewModel.groupData.totals"
						[scrollLeft]="scrollLeftValue"
						[itemWidth]="itemWidth"
						[headerProperties]="viewModel.headerProperties"
						[displayProperties]="viewModel.groupData.totals.displayProperties"
						[clickableHeader]="clickableHeader"
						[dateArray]="viewModel.dateArray"
						[summaryColumn]="viewModel.summaryColumn"
						[columnStyles]="viewModel.columnStyles"
						[width]="mainScroller.offsetWidth"
						[nestedLevel]="1"
						(itemClicked)="itemClicked.emit($event)"
						[sortable]="sortable"
						(sortedGroup)="sortedGroup.emit($event)">
					</app-scrollable-property-container>
				</div>
			</mat-expansion-panel>
			<div *ngIf="viewModel.groupData.totals.altChildrenExpanded">
				<div
					*ngFor="let childId of viewModel.groupData.totals.altChildren; let idx = index"
					[ngClass]="{
						'cell-bottom-border': viewModel.groupData.totals.altChildren && idx !== viewModel.groupData.totals.altChildren.length - 1,
					}">
					<app-scrollable-child-group
						#child
						(click)="child.toggleExpand(); $event.stopPropagation()"
						class="child-row"
						[dateArray]="viewModel.dateArray"
						[summaryColumn]="viewModel.summaryColumn"
						[disableMenus]="disableMenus"
						[columnStyles]="viewModel.columnStyles"
						[group]="viewModel.groupData.totals.childData.groups[childId]"
						[scrollLeft]="scrollLeftValue"
						[nestedLevel]="1"
						[clickableHeader]="clickableHeader"
						[clickableLabel]="clickableLabel"
						[itemWidth]="itemWidth"
						[width]="mainScroller.offsetWidth"
						[sortable]="sortable"
						[editable]="editable"
						(customMenuClicked)="customMenuClicked.emit($event)"
						[disableExpandProperties]="disableExpandProperties">
						<div
							class="child-title-text"
							[style.marginLeft.px]="
								!viewModel.groupData.totals.childData.groups[childId].disabledExpand &&
								(viewModel.groupData.totals.childData.groups[childId].children.length > 0 || !disableExpandProperties)
									? 0
									: 72
							">
							{{ viewModel.groupData.totals.childData.groups[childId].groupInfo }}
						</div>
					</app-scrollable-child-group>
				</div>
			</div>
			<mat-expansion-panel #expansionPanel *ngIf="viewModel.groupData.balances" [expanded]="viewModel.groupData.balances.expanded" [disabled]="true">
				<mat-expansion-panel-header class="recon-data-header" [ngClass]="{ 'cell-bottom-border': expansionPanel.expanded }">
					<div class="flex w-100">
						<div
							class="title-item alt-child-icon-button"
							[style.width]="sanitizer.bypassSecurityTrustStyle('calc(' + 100.0 / (viewModel.dateArray.length + 1) + '% - 36px)')">
							<button
								class="mat-icon-button"
								mat-icon-button
								data-html2canvas-ignore="true"
								matTooltipShowDelay="700"
								(click)="onAltChildrenIconClick(viewModel.groupData.balances); $event.stopPropagation()"
								*ngIf="viewModel.groupData.balances.altChildren && viewModel.groupData.balances.altChildren.length">
								<mat-icon class="alt-child-icon" [ngClass]="{ 'trovata-blue-text': viewModel.groupData.balances.altChildrenExpanded }">{{
									viewModel.groupData.balances.altChildrenExpanded ? 'schedule' : 'history_toggle_off'
								}}</mat-icon>
							</button>
							<button
								class="mat-icon-button"
								mat-icon-button
								data-html2canvas-ignore="true"
								matTooltipShowDelay="700"
								(click)="toggleBalancesExpand(); $event.stopPropagation()"
								*ngIf="!disableExpandTotals && !disableExpandProperties">
								<mat-icon *ngIf="!expansionPanel.expanded" class="child-expansion-button">arrow_right</mat-icon>
								<mat-icon *ngIf="expansionPanel.expanded" class="child-expansion-button">arrow_drop_down</mat-icon>
							</button>
							<div
								class="title-text bold"
								[style.marginLeft.px]="!disableExpandTotals && !disableExpandProperties ? 0 : 40"
								[ngClass]="{ 'clickable-value': clickableLabel && !viewModel.groupData.balances.disableGroupLabelClick, 'ml-40': disableExpandTotals }"
								(click)="
									onClick(
										clickableLabel && !viewModel.groupData.balances.disableGroupLabelClick,
										viewModel.groupData.balances,
										null,
										null,
										true,
										$event,
										expansionPanel
									)
								">
								{{ viewModel.groupData.balances.groupInfo }}
							</div>
							<ng-container *ngFor="let marker of viewModel.groupData.balances.headerMarkers">
								<span class="header-marker" [class]="marker.class" [matTooltip]="marker.tooltip">
									<mat-icon *ngIf="marker.icon" [class]="marker.iconClass">{{ marker.icon }}</mat-icon>
									<span *ngIf="marker.text" class="marker-text">{{ marker.text }}</span>
								</span>
							</ng-container>
						</div>
						<div
							class="header-alignment-area cell-right-border cell-left-border"
							[style.width]="sanitizer.bypassSecurityTrustStyle('calc(' + (100.0 - 100.0 / (viewModel.dateArray.length + 1)) + '% - 56px)')">
							<div #scroller class="viewport w-100" [style.width.px]="mainScroller.offsetWidth">
								<ng-container *ngFor="let date of viewModel.dateArray; let idx = index">
									<ng-template
										[ngTemplateOutlet]="dataCell"
										[ngTemplateOutletContext]="{ idx: idx, dataAccessor: date, groupData: viewModel.groupData.balances, isSummaryCol: false }"></ng-template>
								</ng-container>
								<ng-container *ngIf="viewModel.summaryColumn">
									<ng-template
										[ngTemplateOutlet]="dataCell"
										[ngTemplateOutletContext]="{
											idx: -1,
											dataAccessor: viewModel.summaryColumn.dataKey,
											groupData: viewModel.groupData.balances,
											isSummaryCol: true,
										}"></ng-template>
								</ng-container>
							</div>
						</div>
						<div class="total-panel-menu-container">
							<div class="scrollable-menu-btn">
								<button
									class="mat-icon-button"
									mat-icon-button
									data-html2canvas-ignore="true"
									matTooltipShowDelay="700"
									[disabled]="disableMenus"
									*ngIf="viewModel.groupData.balances.customMenuOptions">
									<mat-icon>more_vert</mat-icon>
								</button>
							</div>
						</div>
					</div>
				</mat-expansion-panel-header>
				<div class="child-rows-background">
					<app-scrollable-property-container
						[group]="viewModel.groupData.balances"
						[scrollLeft]="scrollLeftValue"
						[itemWidth]="itemWidth"
						[clickableHeader]="clickableHeader"
						[displayProperties]="viewModel.displayProperties"
						[dateArray]="viewModel.dateArray"
						[summaryColumn]="viewModel.summaryColumn"
						[columnStyles]="viewModel.columnStyles"
						[width]="mainScroller.offsetWidth"
						[nestedLevel]="1"
						(itemClicked)="itemClicked.emit($event)"
						[sortable]="sortable"
						(sortedGroup)="sortedGroup.emit($event)">
					</app-scrollable-property-container>
				</div>
			</mat-expansion-panel>
			<div *ngIf="viewModel.groupData.balances && viewModel.groupData.balances.altChildrenExpanded">
				<div
					*ngFor="let childId of viewModel.groupData.balances.altChildren; let idx = index"
					[ngClass]="{
						'cell-bottom-border': viewModel.groupData.balances.altChildren && idx !== viewModel.groupData.balances.altChildren.length - 1,
					}">
					<app-scrollable-child-group
						#child
						(click)="child.toggleExpand(); $event.stopPropagation()"
						class="child-row"
						[dateArray]="viewModel.dateArray"
						[summaryColumn]="viewModel.summaryColumn"
						[disableMenus]="disableMenus"
						[columnStyles]="viewModel.columnStyles"
						[group]="viewModel.groupData.balances.childData.groups[childId]"
						[scrollLeft]="scrollLeftValue"
						[nestedLevel]="1"
						[clickableHeader]="clickableHeader"
						[clickableLabel]="clickableLabel"
						[itemWidth]="itemWidth"
						[width]="mainScroller.offsetWidth"
						[sortable]="sortable"
						[editable]="editable"
						(customMenuClicked)="customMenuClicked.emit($event)"
						[disableExpandProperties]="disableExpandProperties">
						<div
							class="child-title-text"
							[style.marginLeft.px]="
								!viewModel.groupData.balances.childData.groups[childId].disabledExpand &&
								(viewModel.groupData.balances.childData.groups[childId].children.length > 0 || !disableExpandProperties)
									? 0
									: 72
							">
							{{ viewModel.groupData.balances.childData.groups[childId].groupInfo }}
						</div>
					</app-scrollable-child-group>
				</div>
			</div>
		</div>
	</mat-accordion>
</div>
<ng-template #iconDisplay let-icon="icon">
	<mat-icon *ngIf="icon.imageType === 'icon' || !icon.imageType" [class]="'menu-opt-icon ' + icon.class"> {{ icon.icon }}</mat-icon>
	<span *ngIf="icon.imageType === 'outline'" class="material-icons-outlined" [class]="'menu-opt-icon ' + icon.class">{{ icon.icon }}</span>
	<img *ngIf="icon.imageType === 'src'" [class]="'menu-opt-icon ' + icon.class" [src]="icon.icon" />
</ng-template>
<ng-template #dataCell let-groupData="groupData" let-dataAccessor="dataAccessor" let-idx="idx" let-isSummaryCol="isSummaryCol">
	<div
		class="flex-item"
		[style.minWidth.px]="itemWidth"
		[ngClass]="{
			'cell-right-border': viewModel.dateArray && idx >= 0 && (idx !== viewModel.dateArray.length - 1 || viewModel.summaryColumn),
			'summary-column': isSummaryCol,
		}"
		[style]="viewModel.columnStyles[dataAccessor] | combineStyles: (groupData.toggleCellStyles ? groupData.cellStylesByDate[dataAccessor] : {})">
		<div [style]="groupData.propertyStylesByDate[dataAccessor]" class="inline-value-text bold">
			<ng-container *ngFor="let headerProperty of groupData.headerProperties">
				<div
					*ngIf="groupData.properties[headerProperty]"
					class="value-container flex-item flex flex-row flex-ai-c flex-jc-sb w-100"
					[style]="groupData.properties[headerProperty].propertyStylesByDate[dataAccessor]"
					[ngClass]="{
						'clickable-value': groupData.properties[headerProperty].clickableValues,
						'red-delta': groupData.match && groupData.match[groupData.groupId] && groupData.match[groupData.groupId][dataAccessor] === false,
						'cell-marker':
							groupData.properties[headerProperty].cellMarker && (groupData.properties[headerProperty].data[dataAccessor] || groupData.placeholderValue),
					}"
					(click)="
						onClick(
							clickableHeader && groupData.properties[headerProperty].clickableValues,
							groupData,
							dataAccessor,
							groupData.properties[headerProperty].isConvertedValue,
							isSummaryCol,
							$event,
							expansionPanel,
							groupData.properties[headerProperty].propertyId
						)
					">
					<div class="left-side-value">
						<ng-container *ngIf="groupData.properties[headerProperty].dataMetadata?.[dataAccessor]?.icon">
							<mat-icon
								[style]="groupData.properties[headerProperty].subPropertyStyles"
								matTooltip="{{ groupData.properties[headerProperty].dataMetadata?.[dataAccessor].tooltip }}"
								class="cell-icon">
								{{ groupData.properties[headerProperty].dataMetadata?.[dataAccessor].icon }}
							</mat-icon>
						</ng-container>
						<div
							class="cell-marker black-alpha-56"
							*ngIf="
								groupData.properties[headerProperty].cellMarker && (groupData.properties[headerProperty].data[dataAccessor] || groupData.placeholderValue)
							">
							{{ groupData.properties[headerProperty].cellMarker }}
						</div>
					</div>
					<div class="right-side-value">
						{{ groupData.properties[headerProperty].data[dataAccessor] || groupData.placeholderValue }}
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</ng-template>
