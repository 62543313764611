import { CurrencyDict } from '@trovata/app/shared/models/currency.model';
import {
	AnalysisBalanceProperty,
	AnalysisBalanceValue,
	AnalysisDataRoot,
	AnalysisDataAggregation,
	AnalysisDisplaySettings,
	AnalysisTransactionValue,
	AnalysisType,
	isConvertedBalance,
	mapGroupTypeToTQLField,
	AnalysisOtherGroupValue,
	sortAnalsyisAggregations,
	AnalysisDataSettings,
	AnalysisTotalGroupValue,
} from './analysis.model';
import { Formatter } from '@trovata/app/shared/utils/formatter';
import { ChartType, HighchartColors, getHighchartsType } from '@trovata/app/shared/models/highcharts.model';
import { Cadence } from 'src/app/shared/models/cadence.model';
import {
	Point,
	PointOptionsObject,
	SeriesAreaOptions,
	SeriesColumnOptions,
	SeriesLineOptions,
	SeriesOptionsType,
	SeriesPieOptions,
	SeriesScatterOptions,
	SeriesZonesOptionsObject,
	Tooltip,
} from 'highcharts';
import { RoundingOption } from './report.model';
import { roundingDict } from '@trovata/app/shared/models/rounding.model';
import { DateTime } from 'luxon';
import { GenericOption } from '@trovata/app/shared/models/option.model';
import { TQLValuesDict } from '@trovata/app/shared/models/tql.model';
import { GroupByKey } from '@trovata/app/shared/utils/key-translator';
import BoostModule from 'highcharts/modules/boost';
import * as Highcharts from 'highcharts';
import { HighChartColors } from '@trovata/app/shared/utils/highchart-colors';
import { v4 as uuid } from 'uuid';

BoostModule(Highcharts);

export class AnalysisChartViewModel {
	pieMax: string;
	pieMin: string;
	dateArray: string[] = [];
	private formatter: Formatter;
	private barColorScheme: SeriesZonesOptionsObject[];
	private colors: string[];
	private yAxisFormatter: (point: any) => string;
	private dateFormat: Intl.DateTimeFormatOptions;
	private metricLineColumn: SeriesLineOptions | any;
	private metricLineValueDisplay: string;
	private drilldownDataDict: object;
	balanceAnalysisOpts: BalanceAnalysisOptions;
	higchartColors: HighchartColors;

	trueRoundingOption: RoundingOption;
	drilldownLevels: {
		type: GroupByKey;
		value: string;
	}[];

	private colorsDict: { [id: string]: string };

	constructor(
		private analysisData: AnalysisDataRoot<AnalysisTransactionValue> | AnalysisDataRoot<AnalysisBalanceValue>,
		private displaySettings: AnalysisDisplaySettings,
		private dataSettings: AnalysisDataSettings,
		private currencyDict: CurrencyDict,
		private valuesDict: TQLValuesDict,
		private miscChartSettings?: MiscChartSettings
	) {
		// creating copy of analysisData so that sorting does not affect other components
		this.analysisData = JSON.parse(JSON.stringify(this.analysisData));
		this.formatter = new Formatter();
		this.higchartColors = HighChartColors;
		this.colors = this.higchartColors.generalChartColors;
		this.barColorScheme = this.higchartColors.barColorScheme;
		sortAnalsyisAggregations(this.analysisData.aggregation, displaySettings?.userOrdered ?? { order: [] });
		this.yAxisFormatter = (point: any): string => this.formatter.formatValue(point.value, this.currencyDict[this.analysisData.currencyConverted], null, true);
		this.balanceAnalysisOpts = new BalanceAnalysisOptions();
		this.trueRoundingOption = roundingDict.get(this.displaySettings.trueRoundingOption);
		this.colorsDict = {};
		this.drilldownLevels = [];
		this.setDateFormat();
		this.dateArray = [];
		this.setDateArray(this.analysisData, true);
	}

	private setDateFormat(): void {
		let dateFormat: Intl.DateTimeFormatOptions = {};
		switch (this.analysisData.cadence) {
			case Cadence.daily:
			case Cadence.weekly: {
				dateFormat = { day: 'numeric', month: 'numeric', year: '2-digit' };
				break;
			}
			case Cadence.quarterly:
			case Cadence.monthly: {
				dateFormat = { month: 'short', year: 'numeric' };
				break;
			}
		}
		this.dateFormat = dateFormat;
	}

	loadDataSeries(pieChartDate?: string): Highcharts.Options {
		this.drilldownDataDict = {};
		let chartOptions: Highcharts.Options;
		this.assignColors(this.analysisData.aggregation);
		switch (this.displaySettings.chartType) {
			case ChartType.column:
				chartOptions = this.setColumnChart();
				break;
			case ChartType.line:
			case ChartType.stackedbar:
			case ChartType.scatter:
				chartOptions = this.setGenericChart();
				break;
			case ChartType.pie:
				chartOptions = this.setPieChart(pieChartDate);
				break;
		}
		chartOptions.chart.type = getHighchartsType(this.displaySettings.chartType);
		this.setMetricLine(chartOptions);
		return chartOptions;
	}

	private assignColors(aggregation: (AnalysisDataAggregation<AnalysisTransactionValue> | AnalysisDataAggregation<AnalysisBalanceValue>)[]): void {
		if (aggregation.length) {
			aggregation.forEach((group: AnalysisDataAggregation<AnalysisTransactionValue> | AnalysisDataAggregation<AnalysisBalanceValue>, index: number) => {
				this.colorsDict[group.value] = this.colors[index];
				this.assignColors(group.aggregation);
			});
		}
	}

	setMetricLine(chartOptions: Highcharts.Options): void {
		if (this.dataSettings.customMetric && this.displaySettings.primaryMetric === 'custom') {
			this.metricLineValueDisplay = this.getDisplayValue(this.dataSettings.customMetric.definition.value);
			this.metricLineColumn = {
				name: 'customMetricLine',
				data: [],
				color: 'black',
				type: 'line',
				dashStyle: 'Dash',
				showInLegend: false,
				lineWidth: 1,
				marker: {
					enabled: false,
				},
				states: {
					hover: {
						enabled: false,
					},
				},
				point: {
					events: {
						mouseOut: function (): void {
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							const chart: any = this.series.chart;
							if (chart.metricLineTooltip) {
								chart.metricLineTooltip.hide();
							}
						},
					},
				},
			};
			this.dateArray.forEach((date: string, i: number) => {
				this.metricLineColumn.data.push({
					y: this.dataSettings.customMetric.definition.value,
					name: this.formatDateLabel(date, i),
				});
			});
			if (chartOptions && this.displaySettings.chartCustomMetric) {
				chartOptions.series.push(this.metricLineColumn);
			}
		} else {
			this.metricLineColumn = undefined;
		}
	}

	getTransactionsDrilldownData(point: Highcharts.Point): SeriesOptionsType[] {
		const drilldown = this.drilldownDataDict[point.options.drilldown];
		if (drilldown.chartType === 'column') {
			const columnDrilldown: SeriesOptionsType = this.createDrilldownForDate(
				drilldown.data,
				drilldown.date,
				drilldown.property,
				'column',
				point.options.custom?.groupBy
			);
			return [columnDrilldown];
		} else {
			const stackedBarDrilldownData: SeriesOptionsType[] = [];
			if (drilldown.data.aggregation.length) {
				drilldown.data.aggregation.forEach(group => {
					this.createNetTrxnSeries(stackedBarDrilldownData, group, null, point.options.custom?.groupBy);
				});
			} else {
				this.createNetTrxnSeries(stackedBarDrilldownData, drilldown.data, true, point.options.custom?.groupBy);
			}

			return stackedBarDrilldownData;
		}
	}

	getBalancesDrilldownData(point: Highcharts.Point): SeriesOptionsType[] {
		const drilldown = this.drilldownDataDict[point.options.drilldown];
		if (drilldown.chartType === 'column') {
			const columnDrilldown: SeriesOptionsType = this.createDrilldownForDate(
				drilldown.data,
				drilldown.date,
				drilldown.property,
				'column',
				point.options.custom?.groupBy
			);
			return [columnDrilldown];
		} else if (drilldown.chartType === 'pie') {
			const pieDrilldown: SeriesOptionsType[] = [];
			this.generatePieChartData(drilldown.date, drilldown.data, pieDrilldown, point.options.custom?.groupBy);
			return pieDrilldown;
		} else {
			const stackedBarDrilldownData: SeriesOptionsType[] = [];
			if (drilldown.data.aggregation.length) {
				drilldown.data.aggregation.forEach(group => {
					this.processBalanceGroup(
						group,
						stackedBarDrilldownData,
						isConvertedBalance(this.balanceAnalysisOpts.getCorrespondingBalanceType[this.analysisData.balanceProperty]),
						null,
						point.options.custom?.groupBy
					);
				});
			} else {
				this.processBalanceGroup(
					drilldown.data,
					stackedBarDrilldownData,
					isConvertedBalance(this.balanceAnalysisOpts.getCorrespondingBalanceType[this.analysisData.balanceProperty]),
					true,
					point.options.custom?.groupBy
				);
			}
			return stackedBarDrilldownData;
		}
	}

	private getDisplayValue(value: number): string {
		return this.formatter.formatValue(value, this.currencyDict[this.analysisData.currencyConverted], this.trueRoundingOption);
	}

	private formatDateLabel(date: string, i?: number): string {
		let formattedDate: string = DateTime.fromISO(date).toLocaleString(this.dateFormat);
		if (this.analysisData.cadence === Cadence.quarterly) {
			const quarterlyDate: DateTime = DateTime.fromISO(date);
			formattedDate = 'Q' + quarterlyDate.quarter + ' ' + quarterlyDate.toFormat('yyyy');
		} else if (i === 0 && this.analysisData.cadence === Cadence.weekly) {
			formattedDate = DateTime.fromISO(this.analysisData.startDate).toLocaleString(this.dateFormat);
		}
		return formattedDate;
	}

	private setColumnChart(): Highcharts.Options {
		const chartOptions: Highcharts.Options = this.getInitialChartOptions();

		if (this.analysisData.type === AnalysisType.transactions) {
			const creditColumn: Array<SeriesOptionsType> = [];
			const debitColumn: Array<SeriesOptionsType> = [];
			const netColumn: Array<SeriesOptionsType> = [];
			const creditObj: SeriesOptionsType = {
				name: 'Credit',
				data: [],
				color: this.higchartColors.grossColors.credit,
				type: 'column',
			};
			const debitObj: SeriesOptionsType = {
				name: 'Debit',
				data: [],
				color: this.higchartColors.grossColors.debit,
				type: 'column',
			};
			const netObj: SeriesOptionsType = {
				name: 'Net',
				data: [],
				type: 'column',
			};
			this.analysisData.summary.forEach((analysisValue, i) => {
				const formattedDate: string = this.formatDateLabel(analysisValue.date, i);
				const creditDrilldownId: string = uuid();
				const debitDrilldownId: string = uuid();
				const netDrilldownId: string = uuid();
				this.drilldownDataDict[creditDrilldownId] = {
					type: 'CREDIT',
					date: analysisValue.date,
					drilldownId: this.analysisData.aggregation,
					property: 'credit',
					chartType: 'column',
				};
				this.drilldownDataDict[debitDrilldownId] = {
					type: 'DEBIT',
					date: analysisValue.date,
					data: this.analysisData.aggregation,
					property: 'debit',
					chartType: 'column',
				};
				this.drilldownDataDict[netDrilldownId] = {
					type: 'NET',
					date: analysisValue.date,
					data: this.analysisData.aggregation,
					property: 'net',
					chartType: 'column',
				};
				const creditData: PointOptionsObject = {
					y: analysisValue.credit,
					drilldown: this.analysisData.aggregation.length ? creditDrilldownId : null,
					custom: { displayValue: this.getDisplayValue(analysisValue.credit), date: analysisValue.date, groupBy: [] },
					name: formattedDate,
					color: this.higchartColors.grossColors.credit,
				};
				const debitData: PointOptionsObject = {
					y: -analysisValue.debit,
					drilldown: this.analysisData.aggregation.length ? debitDrilldownId : null,
					custom: { displayValue: this.getDisplayValue(analysisValue.debit), date: analysisValue.date, groupBy: [] },
					name: formattedDate,
					color: this.higchartColors.grossColors.debit,
				};
				const netData: PointOptionsObject = {
					y: analysisValue.net,
					drilldown: this.analysisData.aggregation.length ? netDrilldownId : null,
					custom: { displayValue: this.getDisplayValue(analysisValue.net), date: analysisValue.date, groupBy: [] },
					name: formattedDate,
					marker: {
						color: 'black',
					},
					color: analysisValue.net > 0 ? this.higchartColors.grossColors.credit : this.higchartColors.grossColors.debit,
				};
				creditObj.data.push(creditData);
				debitObj.data.push(debitData);
				netObj.data.push(netData);
			});
			creditColumn.push(creditObj);
			debitColumn.push(debitObj);
			netColumn.push(netObj);
			if (!this.miscChartSettings.isNet) {
				chartOptions.series = [...creditColumn, ...debitColumn];
			} else {
				chartOptions.series = [...netColumn];
			}
		} else if (this.analysisData.type === AnalysisType.balances) {
			const balanceType: string = this.balanceAnalysisOpts.getCorrespondingBalanceType(this.analysisData.balanceProperty);
			const isConverted: boolean = isConvertedBalance(this.balanceAnalysisOpts.getCorrespondingBalanceType(this.analysisData.balanceProperty));
			let yBalanceType: string;
			if (isConverted) {
				yBalanceType = balanceType;
			} else {
				yBalanceType = balanceType + 'Converted';
			}

			const positiveColumn: Array<SeriesOptionsType> = [];
			const negativeColumn: Array<SeriesOptionsType> = [];
			const netColumn: Array<SeriesOptionsType> = [];
			const positiveObj: SeriesOptionsType = {
				name: 'Positive',
				data: [],
				color: this.higchartColors.grossColors.credit,
				type: 'column',
			};
			const negativeObj: SeriesOptionsType = {
				name: 'Negative',
				data: [],
				color: this.higchartColors.grossColors.debit,
				type: 'column',
			};
			const netObj: SeriesOptionsType = {
				name: 'Net',
				data: [],
				type: 'column',
			};
			this.analysisData.summary.forEach((analysisValue, i) => {
				const positiveDrilldownId: string = uuid();
				const negativeDrilldownId: string = uuid();
				const netDrilldownId: string = uuid();
				this.drilldownDataDict[positiveDrilldownId] = {
					type: 'POSITIVE',
					date: analysisValue.date,
					data: this.analysisData.aggregation,
					property: 'positive',
					chartType: 'column',
				};
				this.drilldownDataDict[negativeDrilldownId] = {
					type: 'NEGATIVE',
					date: analysisValue.date,
					data: this.analysisData.aggregation,
					property: 'negative',
					chartType: 'column',
				};
				this.drilldownDataDict[netDrilldownId] = {
					type: 'NET',
					date: analysisValue.date,
					data: this.analysisData.aggregation,
					property: 'net',
					chartType: 'column',
				};
				const formattedDate: string = this.formatDateLabel(analysisValue.date, i);
				const positiveData: PointOptionsObject = {
					y: analysisValue.positiveBalanceConverted,
					drilldown: this.analysisData.aggregation.length ? positiveDrilldownId : null,
					custom: { displayValue: this.getDisplayValue(analysisValue.positiveBalanceConverted), date: analysisValue.date, groupBy: [] },
					name: formattedDate,
					color: this.higchartColors.grossColors.credit,
				};
				const negativeData: PointOptionsObject = {
					y: analysisValue.negativeBalanceConverted,
					drilldown: this.analysisData.aggregation.length ? negativeDrilldownId : null,
					custom: { displayValue: this.getDisplayValue(analysisValue.negativeBalanceConverted), date: analysisValue.date, groupBy: [] },
					name: formattedDate,
					color: this.higchartColors.grossColors.debit,
				};
				const netData: PointOptionsObject = {
					y: analysisValue[yBalanceType],
					drilldown: this.analysisData.aggregation.length ? netDrilldownId : null,
					custom: { displayValue: this.getDisplayValue(analysisValue[yBalanceType]), date: analysisValue.date, groupBy: [] },
					name: formattedDate,
					marker: {
						color: 'black',
					},
					color: analysisValue[yBalanceType] > 0 ? this.higchartColors.grossColors.credit : this.higchartColors.grossColors.debit,
				};
				positiveObj.data.push(positiveData);
				negativeObj.data.push(negativeData);
				netObj.data.push(netData);
			});
			positiveColumn.push(positiveObj);
			negativeColumn.push(negativeObj);
			netColumn.push(netObj);
			if (!this.miscChartSettings.isNet) {
				chartOptions.series = [...positiveColumn, ...negativeColumn];
			} else {
				chartOptions.series = [...netColumn];
			}
		}
		chartOptions.chart.spacingBottom = 0;
		chartOptions.plotOptions.column.zones = this.barColorScheme;
		chartOptions.plotOptions.column.borderRadius = 3;

		return chartOptions;
	}

	private setGenericChart(): Highcharts.Options {
		const chartOptions: Highcharts.Options = this.getInitialChartOptions();

		if (this.analysisData.type === AnalysisType.balances) {
			const isConverted: boolean = isConvertedBalance(this.balanceAnalysisOpts.getCorrespondingBalanceType[this.analysisData.balanceProperty]);
			const mainSeries: SeriesOptionsType[] = [];

			if (!this.analysisData.aggregation.length) {
				this.processBalanceGroup(
					{ value: null, key: '', type: AnalysisType.balances, aggregation: [], summary: this.analysisData.summary },
					mainSeries,
					isConverted
				);
			} else {
				this.analysisData.aggregation.forEach(group => {
					this.processBalanceGroup(group, mainSeries, isConverted);
				});
			}

			chartOptions.yAxis['labels'].formatter = this.yAxisFormatter;
			chartOptions.series = mainSeries;
			chartOptions.title.text = undefined;
		} else if (this.analysisData.type === AnalysisType.transactions) {
			const netLine: SeriesOptionsType[] = [];
			if (this.analysisData.aggregation.length) {
				this.analysisData.aggregation.forEach(group => {
					this.createNetTrxnSeries(netLine, group);
				});
			} else {
				this.createNetTrxnSeries(netLine, { value: null, key: '', type: AnalysisType.transactions, aggregation: [], summary: this.analysisData.summary });
			}

			chartOptions.series = [...netLine];
			chartOptions.plotOptions.column.zones = undefined;
			chartOptions.plotOptions.column.borderRadius = 0;
		}
		chartOptions.chart.spacingBottom = 0;
		return chartOptions;
	}

	private setDateArray(aggregation: AnalysisDataRoot | AnalysisDataAggregation<AnalysisBalanceValue | AnalysisTransactionValue>, topLevel?: boolean): void {
		if (aggregation.summary?.length) {
			let dates: string[];
			if (this.displaySettings.chartType === ChartType.pie && topLevel) {
				dates = aggregation.summary
					.filter((balance: AnalysisBalanceValue | AnalysisTransactionValue) => {
						let hasNumber: boolean = false;
						Object.keys(balance).forEach((key: string) => {
							if (balance[key] && typeof balance[key] === 'number') {
								hasNumber = true;
							}
						});
						return hasNumber;
					})
					.map((analysisVal: AnalysisTransactionValue | AnalysisBalanceValue) => analysisVal.date);
			} else {
				dates = aggregation.summary.map((analysisVal: AnalysisTransactionValue | AnalysisBalanceValue) => analysisVal.date);
			}
			dates.forEach((date: string) => this.addToDateArray(date));
			this.dateArray.sort((a: string, b: string) => (DateTime.fromISO(a) > DateTime.fromISO(b) ? 1 : -1));
		} else if (aggregation.aggregation?.length) {
			aggregation.aggregation.forEach((childAgg: AnalysisDataAggregation<AnalysisBalanceValue | AnalysisTransactionValue>) => this.setDateArray(childAgg));
		}
	}

	private setPieChart(pieChartDate?: string): Highcharts.Options {
		const chartOptions: Highcharts.Options = this.getInitialChartOptions();

		const date: string = DateTime.fromISO(pieChartDate).toLocaleString(this.dateFormat);
		if (this.analysisData.type === AnalysisType.balances) {
			const mainSeries: SeriesOptionsType[] = [];

			this.generatePieChartData(date, this.analysisData, mainSeries);
			chartOptions.series = mainSeries;
			chartOptions.chart.spacingBottom = 20;
		}
		return chartOptions;
	}

	private generatePieChartData(
		formattedDate: string,
		data: AnalysisDataAggregation<AnalysisBalanceValue>,
		mainSeries: SeriesOptionsType[],
		parentGroupBy?: { type: string; value: string }[]
	): void {
		const isConverted: boolean = isConvertedBalance(this.balanceAnalysisOpts.getCorrespondingBalanceType(this.analysisData.balanceProperty));
		const seriesData: PointOptionsObject[] = [];
		if (data.aggregation.length) {
			data.aggregation.forEach((group: AnalysisDataAggregation<AnalysisBalanceValue>) => {
				const balances: AnalysisBalanceValue[] = group.summary;
				const balance: AnalysisBalanceValue = balances.find((val: AnalysisBalanceValue, i: number) => this.formatDateLabel(val.date, i) === formattedDate);
				if (balance) {
					const groupBy: {
						type: string;
						value: string;
					}[] = parentGroupBy ? [...parentGroupBy] : [];
					const index: number = groupBy.findIndex(parentGroup => parentGroup.type === group.key);
					if (index >= 0) {
						groupBy[index] = { value: group.value, type: group.key };
					} else if (group.key) {
						groupBy.push({ value: group.value, type: group.key });
					}
					const pieDrilldownId: string = uuid();
					this.drilldownDataDict[pieDrilldownId] = { data: group, chartType: 'pie', date: formattedDate };
					const obj: PointOptionsObject = {
						name: this.nameForId(group.key, group.value, group.type),
						y: balance[this.balanceAnalysisOpts.getCorrespondingBalanceType(this.analysisData.balanceProperty)],
						custom: {
							groupBy,
							curr:
								(this.currencyDict && !isConverted ? this.currencyDict[group.uniformCurrency] : null) || this.currencyDict[this.analysisData.currencyConverted],
						},
						drilldown: group.aggregation.length ? pieDrilldownId : null,
					};
					if (obj.y > 0) {
						seriesData.push(obj);
					} else {
						// pieZeroValues.push(obj.name);
					}
				}
			});
		} else if (data.summary?.find((val: AnalysisBalanceValue, i: number) => this.formatDateLabel(val.date, i) === formattedDate)) {
			const balance: AnalysisBalanceValue = data.summary.find((val: AnalysisBalanceValue, i: number) => this.formatDateLabel(val.date, i) === formattedDate);
			const obj: PointOptionsObject = {
				name: 'All Accounts',
				y: balance[this.balanceAnalysisOpts.getCorrespondingBalanceType(this.analysisData.balanceProperty)],
				custom: {
					curr:
						(this.currencyDict && !isConverted ? this.currencyDict[this.analysisData.uniformCurrency] : null) ||
						this.currencyDict[this.analysisData.currencyConverted],
				},
			};
			seriesData.push(obj);
		}
		mainSeries.push({
			name: formattedDate,
			type: 'pie',
			data: seriesData,
		});
	}

	private processBalanceGroup(
		group: AnalysisDataAggregation<AnalysisBalanceValue>,
		mainSeries: Array<SeriesOptionsType>,
		isConverted: boolean,
		noDrilldown?: boolean,
		parentGroupBy?: { type: string; value: string }[]
	): void {
		const values: AnalysisBalanceValue[] | AnalysisTransactionValue[] = group.summary;
		const seriesObj: SeriesScatterOptions | SeriesLineOptions | SeriesColumnOptions = {
			name: this.nameForId(group.key, group.value, group.type),
			data: [],
			type: <'column' | 'line' | 'scatter'>getHighchartsType(this.displaySettings.chartType),
			color: this.colorsDict[group.value],
		};
		const groupBy: {
			type: string;
			value: string;
		}[] = parentGroupBy ? [...parentGroupBy] : [];
		const index: number = groupBy.findIndex(parentGroup => parentGroup.type === group.key);
		if (index >= 0) {
			groupBy[index] = { value: group.value, type: group.key };
		} else if (group.key) {
			groupBy.push({ value: group.value, type: group.key });
		}
		let drilldown: Object;
		if (!noDrilldown) {
			if (this.displaySettings.chartType === ChartType.column) {
				// if basic bar chart, we show all the groups in the groupby on a single day
				drilldown = this.analysisData.aggregation.length ? { data: this.analysisData.aggregation, chartType: 'column' } : null;
			} else {
				// if stacked bar chart, we show the next groupby over the current date range
				drilldown = group.key ? { data: group } : null;
			}
		}
		if (values) {
			values.forEach(balance => {
				let formattedDate: string;
				if (this.analysisData.cadence === Cadence.quarterly) {
					const date: DateTime = DateTime.fromISO(balance.date);
					formattedDate = 'Q' + date.quarter + ' ' + date.toFormat('yyyy');
				} else if (this.analysisData.cadence === Cadence.weekly) {
					const days: number = this.analysisData.balanceProperty.toLowerCase().includes('opening') ? 4 : 0;
					formattedDate = DateTime.fromISO(balance.date).minus({ days }).toLocaleString(this.dateFormat);
				} else {
					formattedDate = DateTime.fromISO(balance.date).toLocaleString(this.dateFormat);
				}
				const balanceType: string = this.balanceAnalysisOpts.getCorrespondingBalanceType(this.analysisData.balanceProperty);
				let yBalanceType: string;
				if (balanceType.includes('Converted')) {
					yBalanceType = balanceType;
				} else {
					yBalanceType = balanceType + 'Converted';
				}
				const balanceDrilldownId: string = uuid();
				this.drilldownDataDict[balanceDrilldownId] = { ...drilldown, date: balance.date };
				const dataObj: PointOptionsObject = {
					y: balance[yBalanceType],
					drilldown: drilldown ? balanceDrilldownId : null,
					custom: {
						groupBy,
						curr:
							(this.currencyDict && !isConverted ? this.currencyDict[group.uniformCurrency] : null) || this.currencyDict[this.analysisData.currencyConverted],
					},
					name: balance.fakeBalance ? `No historical data for ${DateTime.fromISO(balance.date).toLocaleString(this.dateFormat)}` : formattedDate,
				};
				if (!balanceType.includes('Converted')) {
					dataObj.custom.nonConvertedY = balance[balanceType];
					dataObj.custom.currencyNative = balance.currencyNative;
				}
				seriesObj.data.push(dataObj);
			});
		}
		mainSeries.push(seriesObj);
	}

	private createNetTrxnSeries(
		netLine: SeriesOptionsType[],
		group: AnalysisDataAggregation<AnalysisTransactionValue>,
		noDrilldown?: boolean,
		parentGroupBy?: { type: string; value: string }[]
	): void {
		const transactions: AnalysisTransactionValue[] = group.summary;
		const seriesObj: SeriesScatterOptions | SeriesAreaOptions | SeriesPieOptions | SeriesColumnOptions = {
			name: this.nameForId(group.key, group.value, group.type),
			data: [],
			id: group.key + group.value,
			type: getHighchartsType(this.displaySettings.chartType),
			color: this.colorsDict[group.value],
		};
		const groupBy: {
			type: string;
			value: string;
		}[] = parentGroupBy ? [...parentGroupBy] : [];
		const index: number = groupBy.findIndex(parentGroup => parentGroup.type === group.key);
		if (index >= 0) {
			groupBy[index] = { value: group.value, type: group.key };
		} else if (group.key) {
			groupBy.push({ value: group.value, type: group.key });
		}
		transactions.forEach((trxn, i) => {
			let formattedDate: string;
			if (this.analysisData.cadence === Cadence.quarterly) {
				const date: DateTime = DateTime.fromISO(trxn.date);
				formattedDate = 'Q' + date.quarter + ' ' + date.toFormat('yyyy');
			} else if (i === 0 && this.analysisData.cadence === Cadence.weekly) {
				formattedDate = DateTime.fromISO(this.analysisData.startDate).toLocaleString(this.dateFormat);
			} else {
				formattedDate = DateTime.fromISO(trxn.date).toLocaleString(this.dateFormat);
			}
			const netTransactionDrilldownId: string = uuid();
			this.drilldownDataDict[netTransactionDrilldownId] = { type: group.key, data: group };
			const dataObj: PointOptionsObject = {
				y: trxn.net,
				drilldown: group.key && !noDrilldown ? netTransactionDrilldownId : null,
				name: formattedDate,
				custom: { displayValue: this.getDisplayValue(trxn.net), date: trxn.date, groupBy },
			};
			if (this.miscChartSettings.anomalyDates) {
				if (this.miscChartSettings.anomalyDates.find((anomalyDate: string) => DateTime.fromISO(anomalyDate).hasSame(DateTime.fromISO(trxn.date), 'day'))) {
					dataObj['color'] = '#EF4848';
				} else {
					dataObj['color'] = '#006CE2';
				}
			}

			seriesObj.data.push(dataObj);
		});
		netLine.push(seriesObj);
	}

	private createDrilldownForDate(
		data: AnalysisDataAggregation<AnalysisTransactionValue>[] | AnalysisDataAggregation<AnalysisBalanceValue>[],
		date: string,
		property: string,
		chartType: string,
		parentGroupBy?: { type: string; value: string }[]
	): SeriesOptionsType {
		const breakDownObj: SeriesColumnOptions = {
			data: [],
			id: data[0].key + date,
			type: chartType as 'column',
			name: this.balanceAnalysisOpts.getGroupByName(data[0].key),
			color: this.colorsDict[property],
		};
		data.forEach((group: AnalysisDataAggregation<AnalysisTransactionValue> | AnalysisDataAggregation<AnalysisBalanceValue>) => {
			const i: number = group.summary.findIndex((analysisValue: AnalysisTransactionValue | AnalysisBalanceValue) => analysisValue.date === date);
			let amount: number;
			if (group.type === AnalysisType.transactions) {
				if (i < 0) {
					amount = null;
				} else if (property === 'net') {
					amount = group.summary[i].credit - group.summary[i].debit;
				} else if (property === 'credit') {
					amount = group.summary[i][property];
				} else if (property === 'debit') {
					amount = -group.summary[i][property];
				}
			} else {
				if (i < 0) {
					amount = null;
				} else if (property === 'positive') {
					amount = group.summary[i]['positiveBalanceConverted'];
				} else if (property === 'negative') {
					amount = group.summary[i]['negativeBalanceConverted'];
				} else {
					amount = group.summary[i][this.analysisData.balanceProperty];
				}
			}

			const groupBy = [...(parentGroupBy ?? []), { value: group.value, type: group.key }];
			const dateDrilldownId: string = uuid();
			this.drilldownDataDict[dateDrilldownId] = { type: group.key, date: date, data: group.aggregation, property, chartType };
			breakDownObj.data.push({
				y: amount,
				drilldown: group.aggregation.length ? dateDrilldownId : null,
				custom: {
					displayValue: this.getDisplayValue(amount),
					curr: this.currencyDict[this.analysisData.currencyConverted],
					date: date,
					groupBy,
				},
				name: this.nameForId(group.key, group.value, group.type),
			});
		});
		breakDownObj.name = `${this.balanceAnalysisOpts.getGroupByName(data[0].key)} - ${this.formatDateLabel(date)}`;

		return breakDownObj;
	}

	private addToDateArray(formattedDate: string): void {
		if (formattedDate && this.dateArray.findIndex((date: string) => formattedDate === date) < 0) {
			this.dateArray.push(formattedDate);
			if (!this.pieMin || DateTime.fromISO(formattedDate) < DateTime.fromISO(this.pieMin)) {
				this.pieMin = formattedDate;
			}
			if (!this.pieMax || DateTime.fromISO(formattedDate) > DateTime.fromISO(this.pieMax)) {
				this.pieMax = formattedDate;
			}
		}
	}

	private getInitialChartOptions(): Highcharts.Options {
		if (this.analysisData.type === AnalysisType.transactions) {
			return {
				accessibility: { enabled: false },
				boost: { useGPUTranslations: true },
				legend: {
					enabled: true,
					maxHeight: 120,
					alignColumns: true,
				},
				chart: {
					spacingBottom: 10,
					backgroundColor: 'transparent',
					type: this.displaySettings.chartType,
					animation: false,
					events: {},
					zooming: {
						mouseWheel: {
							enabled: false,
						},
					},
					style: {
						fontFamily: 'AkkuratLLWeb',
						fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
						fontSize: '16px',
					},
				},
				series: [],
				drilldown: {
					breadcrumbs: {
						showFullPath: false,
						format: '◁ Back',
					},
				},
				exporting: {
					enabled: false,
				},
				colors: this.colors,
				title: {
					text: undefined,
					useHTML: false,
					style: {
						color: '#5f5f5f',
					},
				},
				credits: {
					enabled: false,
				},
				xAxis: {
					type: 'category',
					lineWidth: 0,
					labels:
						this.miscChartSettings.anomalyDates &&
						this.miscChartSettings.anomalyDates.find(anomalyDate => DateTime.fromISO(anomalyDate).hasSame(DateTime.fromISO(anomalyDate), 'day'))
							? {
									style: {
										color: 'red',
									},
								}
							: {},
				},
				yAxis: {
					title: null,
					startOnTick: false,
					endOnTick: true,
					labels: {
						formatter: this.yAxisFormatter,
					},
					opposite: true,
					reversedStacks: false,
				},
				tooltip: {
					formatter: (a: Tooltip): string | false => {
						const point: Point = a.chart.hoverPoint;
						if (point.series.name === 'customMetricLine') {
							const chart: any = a.chart;
							const lastPoint: Highcharts.Point = point.series.points[point.series.points.length - 1];
							const height: number = lastPoint.pos(true, lastPoint.plotY)[1];
							const width: number = point.series.chart.xAxis[0].len;
							if (!chart.metricLineTooltip) {
								chart.metricLineTooltip = chart.renderer
									.label('', width - 27, height - 9)
									.attr({
										r: 10,
										fill: '#00000099',
										'font-size': '10px',
										zIndex: 5,
									})
									.css({
										color: 'white',
									})
									.add();
							}
							chart.metricLineTooltip.show().attr({
								text: 'Goal',
							});
							return false;
						}
						const category: string | number = point.name ? point.name : point.category;
						const displayVal: string = point.options.custom['displayValue'];
						return `<div style="font-size: 10px; text-align: center">
                      ${category}
                      <span style="color: ${point.color}">\u25CF</span>
                      ${point.series.name}
                    </div>
                    <div style="text-align:center"><strong>${displayVal}</strong></div>
                    <div style="font-size:8px; text-align:center;">${this.analysisData.currencyConverted} Equiv.</div>`;
					},
					footerFormat: null,
					headerFormat: null,
					useHTML: true,
					style: {
						fontFamily: 'AkkuratLLWeb',
						fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
						fontSize: '16px',
					},
				},
				plotOptions: {
					series: {
						point: {
							events: {
								click: null,
							},
						},
					},
					column: {
						stacking: 'normal',
						visible: true,
						animation: false,
					},
					area: {
						stacking: 'normal',
						marker: {
							enabled: false,
						},
					},
					pie: {
						dataLabels: {
							enabled: true,
						},
						size: '100%',
						allowPointSelect: true,
						cursor: 'pointer',
					},
				},
				responsive: {
					rules: [
						{
							condition: {
								maxHeight: 300,
							},
							chartOptions: {
								legend: {
									enabled: false,
								},
								chart: {
									spacingBottom: 1,
									zooming: {
										type: null,
									},
									animation: false,
									spacingLeft: 0,
									spacingRight: 0,
									spacingTop: 0,
									style: {
										fontFamily: 'AkkuratLLWeb',
										fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
										fontSize: '16px',
									},
								},
								xAxis: {
									labels: {
										enabled: false,
									},
								},
								yAxis: {
									labels: {
										enabled: false,
									},
									gridLineColor: 'transparent',
									plotLines: [
										{
											color: '#00000014',
											width: 1,
											value: 0,
											zIndex: 3,
										},
									],
									reversedStacks: false,
								},
								plotOptions: {
									series: {
										pointPlacement: 'on',
										animation: false,
									},
									area: {
										marker: {
											enabled: false,
										},
									},
									pie: {
										allowPointSelect: false,
										slicedOffset: 0,
										size: '90%',
										dataLabels: {
											alignTo: 'connectors',
											distance: 5,
											style: {
												textOverflow: 'ellipsis',
												padding: '0px 5px 0px 5px',
												width: 80,
											},
										},
									},
									column: {
										pointPlacement: 'between',
									},
									columnrange: {
										pointPlacement: 'between',
										dataLabels: {
											formatter: function () {
												const prefix = this.y === this.point['high'] ? 'O' : 'C';
												return `${prefix}`;
											},
										},
									},
								},
							},
						},
					],
				},
			};
		} else if (this.analysisData.type === AnalysisType.balances) {
			return {
				accessibility: { enabled: false },
				chart: {
					type: null,
					backgroundColor: 'transparent',
					spacingBottom: 0,
					zooming: {
						mouseWheel: {
							enabled: false,
						},
					},
					events: {},
					style: {
						fontFamily: 'AkkuratLLWeb',
						fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
						fontSize: '16px',
					},
				},
				colors: this.colors,
				series: [],
				drilldown: {
					breadcrumbs: {
						showFullPath: false,
						format: '◁ Back',
					},
				},
				legend: {
					enabled: true,
				},
				exporting: {
					enabled: false,
				},
				title: {
					text: undefined,
					align: 'center',
					verticalAlign: 'middle',
					y: 100,
				},
				credits: {
					enabled: false,
				},
				xAxis: {
					lineWidth: 0,
					type: 'category',
					labels: {
						enabled: !this.miscChartSettings.isSnack,
					},
				},
				yAxis: {
					visible: !this.miscChartSettings.isSnack,
					title: null,
					opposite: true,
					startOnTick: false,
					endOnTick: false,
					labels: {
						enabled: !this.miscChartSettings.isSnack,
						formatter: null,
					},
					reversedStacks: false,
				},
				tooltip: {
					formatter: (a: Tooltip): string | false => {
						const point: Point = a.chart.hoverPoint;
						if (point.name && point.name.includes('No historical data')) {
							return `<div style="text-align:center"><strong>${point.name}</strong></div>`;
						}
						const category: string | number = point.name ? point.name : point.category;
						if (point.series.name === 'customMetricLine') {
							const chart: any = a.chart;
							const lastPoint: Highcharts.Point = point.series.points[point.series.points.length - 1];
							const height: number = lastPoint.pos(true, lastPoint.plotY)[1];
							const width: number = point.series.chart.xAxis[0].len;
							if (!chart.metricLineTooltip) {
								chart.metricLineTooltip = chart.renderer
									.label('', width - 27, height - 9)
									.attr({
										r: 10,
										fill: '#00000099',
										'font-size': '10px',
										zIndex: 5,
									})
									.css({
										color: 'white',
									})
									.add();
							}
							chart.metricLineTooltip.show().attr({
								text: 'Goal',
							});
							return false;
						}
						const displayString: string = this.formatter.formatValue(
							point.y,
							point.options.custom['curr'] || this.currencyDict[this.analysisData.currencyConverted],
							this.trueRoundingOption
						);
						const percent: number = point.percentage && Math.round(point.percentage * 100) / 100;
						let returnString: string = `<div style="font-size: 10px; text-align: center">
                                  ${category}
                                  <span >\u25CF</span>
                                  ${point.series.name}
                                </div>
                                <div style="text-align:center"><strong>${displayString}</strong></div>`;
						if (point?.options?.custom?.nonConvertedY) {
							const displayStringNonconverted: string = this.formatter.formatValue(
								point.options.custom.nonConvertedY,
								this.currencyDict[point.options.custom['currencyNative']],
								this.trueRoundingOption
							);
							returnString += `<div style="text-align:center"><strong>${displayStringNonconverted}</strong></div>`;
						}
						if (this.displaySettings.chartType === ChartType.pie) {
							returnString = returnString + `<div style="text-align:center"><strong>${percent}%</strong></div>`;
						}
						return returnString;
					},
					headerFormat: null,
					useHTML: true,
					style: {
						fontFamily: 'AkkuratLLWeb',
						fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
						fontSize: '16px',
					},
				},
				plotOptions: {
					series: {
						point: {
							events: {
								click: null,
							},
						},
					},
					column: {
						stacking: 'normal',
						events: {
							legendItemClick: function () {
								return false;
							},
						},
						minPointLength: this.miscChartSettings.isSnack ? 5 : 0,
					},
					area: {
						stacking: 'normal',
						marker: {
							enabled: false,
						},
					},
					pie: {
						dataLabels: {
							enabled: true,
						},
						size: '100%',
						allowPointSelect: true,
						cursor: 'pointer',
						slicedOffset: 0,
					},
				},
				responsive: {
					rules: [
						{
							condition: {
								maxHeight: 300,
							},
							chartOptions: {
								legend: {
									enabled: false,
								},
								chart: {
									spacingBottom: this.displaySettings.chartType === ChartType.pie || this.miscChartSettings.isSnack ? 5 : 1,
									zooming: {
										type: null,
									},
									animation: false,
									spacingLeft: ChartType.pie ? 5 : 0,
									spacingRight: ChartType.pie ? 5 : 0,
									spacingTop: ChartType.pie || this.miscChartSettings.showYAxis ? 5 : 0,
									style: {
										fontFamily: 'AkkuratLLWeb',
										fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
										fontSize: '16px',
									},
								},
								title: {
									text: null,
								},
								xAxis: {
									labels: {
										enabled: false,
									},
								},
								yAxis: {
									// visible: !!this.miscChartSettings.showYAxis,
									title: null,
									labels: {
										enabled: false,
									},
									// gridLineColor: this.miscChartSettings.showYAxis ? '#e6e6e6' : 'transparent',
									gridLineColor: 'transparent',
									plotLines: [
										{
											color: '#00000014',
											width: 1,
											value: 0,
											zIndex: 3,
										},
									],
									reversedStacks: false,
								},
								plotOptions: {
									series: {
										pointPlacement: 'between',
										animation: false,
									},
									area: {
										pointPlacement: 'on',
									},
									line: {
										pointPlacement: 'on',
									},
									pie: {
										allowPointSelect: false,
										slicedOffset: 0,
										size: '90%',
										dataLabels: {
											alignTo: 'connectors',
											distance: 5,
											style: {
												textOverflow: 'ellipsis',
												padding: '0px 5px 0px 5px',
												width: 80,
											},
										},
									},
									column: {
										stacking: 'normal',
										visible: true,
										borderRadius: this.displaySettings.chartType === ChartType.column ? 3 : 0,
									},
									columnrange: {
										dataLabels: {
											formatter: function () {
												const prefix = this.y === this.point['high'] ? 'O' : 'C';
												return `${prefix}`;
											},
										},
									},
								},
							},
						},
						{
							condition: {
								maxHeight: 128,
							},
							chartOptions: {
								xAxis: {
									visible: false,
									endOnTick: false,
									startOnTick: false,
								},
								yAxis: {
									visible: !!this.miscChartSettings.showYAxis,
									title: null,
									labels: {
										enabled: !!this.miscChartSettings.showYAxis,
									},
									min: !this.miscChartSettings.showYAxis ? 0 : null,
									minRange: 2,
									reversedStacks: false,
								},
								plotOptions: {
									column: {
										minPointLength: 5,
									},
								},
							},
						},
					],
				},
			};
		}
	}

	private nameForId(key: string, id: string, type: AnalysisType): string {
		const filter: GenericOption = this.valuesDict[mapGroupTypeToTQLField(key)]?.values?.find(option => option.id === id);
		if (filter) {
			return filter.displayValue;
		} else if (id === AnalysisOtherGroupValue) {
			return 'Other ' + (type === AnalysisType.balances ? 'Accounts' : 'Transactions');
		} else if (id === AnalysisTotalGroupValue || !id) {
			return 'All ' + (type === AnalysisType.balances ? 'Accounts' : 'Transactions');
		} else {
			return id;
		}
	}

	pieFilter = (d: DateTime | null): boolean => {
		if (d && this.dateArray) {
			const formattedPieDate: string = d.toFormat('yyyy-MM-dd');
			return this.dateArray.findIndex((date: string) => date === formattedPieDate) >= 0;
		} else {
			return false;
		}
	};
}

export class AnalysisBreakDownGroup {
	id: string;
	data = [];
	keys: string[] = ['name', 'y', 'drilldown'];
	name: string;
	type: string;
	constructor(id: string, name: string, chartType: string) {
		this.id = id;
		this.name = name;
		this.type = chartType;
	}
}

export class BalanceAnalysisOptions {
	primaryBalanceOptions: BalanceAnalysisOption[] = [
		{ value: 'compositeBalanceConverted', view: 'Composite Balance Converted' },
		{ value: 'compositeBalance', view: 'Composite Balance' },
	];

	otherBalanceOptions: BalanceAnalysisOption[] = [
		{
			value: 'bankClosingLedgerConverted',
			view: 'Closing Ledger Converted',
		},
		{
			value: 'bankClosingLedger',
			view: 'Closing Ledger',
		},
		{
			value: 'bankClosingAvailableConverted',
			view: 'Closing Available Converted',
		},
		{ value: 'bankClosingAvailable', view: 'Closing Available' },
		{ value: 'bankOpeningLedgerConverted', view: 'Opening Ledger Converted' },
		{ value: 'bankOpeningLedger', view: 'Opening Ledger' },
		{
			value: 'bankOpeningAvailableConverted',
			view: 'Opening Available Converted',
		},
		{ value: 'bankOpeningAvailable', view: 'Opening Available' },
		{
			value: 'bankCurrentAvailableConverted',
			view: 'Current Available Converted',
		},
		{ value: 'bankCurrentAvailable', view: 'Current Available' },
		{
			value: 'bankOpeningClosingLedgerConverted',
			view: 'Opening/Closing Ledger Converted',
		},
		{ value: 'bankOpeningClosingLedger', view: 'Opening/Closing Ledger' },
	];

	groupBy: BalanceAnalysisOption[] = [
		// { value: '', view: 'None' },
		{ value: 'currency', view: 'Currency' },
		{ value: 'institutionId', view: 'Institution' },
		{ value: 'accountId', view: 'Account' },
	];

	trxnGroupBy: BalanceAnalysisOption[] = [{ value: 'tags', view: 'Tag' }];

	balanceGroupBy: BalanceAnalysisOption[] = [
		{ value: 'entityGroupingId', view: 'Entity' },
		{ value: 'regionGroupingId', view: 'Region' },
		{ value: 'divisionGroupingId', view: 'Division' },
	];

	constructor() {}

	getSecondaryBalanceType(balanceType: AnalysisBalanceProperty): AnalysisBalanceProperty {
		switch (balanceType) {
			case AnalysisBalanceProperty.bankClosingAvailableConverted:
				return AnalysisBalanceProperty.bankOpeningAvailableConverted;
			case AnalysisBalanceProperty.bankOpeningAvailableConverted:
				return AnalysisBalanceProperty.bankClosingAvailableConverted;
			case AnalysisBalanceProperty.bankClosingAvailable:
				return AnalysisBalanceProperty.bankOpeningAvailable;
			case AnalysisBalanceProperty.bankOpeningAvailable:
				return AnalysisBalanceProperty.bankClosingAvailable;
			case AnalysisBalanceProperty.bankClosingLedgerConverted:
				return AnalysisBalanceProperty.bankOpeningLedgerConverted;
			case AnalysisBalanceProperty.bankOpeningLedgerConverted:
				return AnalysisBalanceProperty.bankClosingLedgerConverted;
			case AnalysisBalanceProperty.bankClosingLedger:
				return AnalysisBalanceProperty.bankOpeningLedger;
			case AnalysisBalanceProperty.bankOpeningLedger:
				return AnalysisBalanceProperty.bankClosingLedger;
		}
	}

	getCorrespondingBalanceType(balanceType: AnalysisBalanceProperty): AnalysisBalanceProperty {
		switch (balanceType) {
			case AnalysisBalanceProperty.bankOpeningClosingLedgerConverted:
				return AnalysisBalanceProperty.bankClosingLedgerConverted;
			case AnalysisBalanceProperty.bankOpeningClosingLedger:
				return AnalysisBalanceProperty.bankClosingLedger;
			default:
				return balanceType;
		}
	}

	getGroupByName(key: string): string {
		switch (key) {
			case 'institution':
				return 'Institution';
			case 'currency':
				return 'Currency';
			case 'account':
			case 'accountId':
				return 'Account';
			// TODO these got changed to something with (Legacy)
			case 'accountGroupA':
				return 'Entity (Legacy)';
			case 'accountGroupB':
				return 'Region (Legacy)';
			case 'accountGroupC':
				return 'Division (Legacy)';
			case 'entityGroupingId':
				return 'Entity';
			case 'entityRegion':
				return 'Entity Region';
			case 'entityDivision':
				return 'Entity Division';
			case 'tag':
				return 'Tag';
			case 'entityId':
				return 'Entity';
			default:
				return null;
		}
	}
}

export interface BalanceAnalysisOption {
	view: string;
	value: string;
}

export interface MiscChartSettings {
	isSnack?: boolean;
	showYAxis?: boolean;
	preventDrilldown?: boolean;
	anomalyDates?: string[];
	isNet?: boolean;
}
