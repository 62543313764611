import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { StatementEntry } from 'src/app/features/statements/models/statements.models';

export enum StatementDialogEnum {
	delete = 'delete',
	settings = 'settings',
	openingBalances = 'openingBalances',
	closingBalances = 'closingBalances',
	creditTransactions = 'creditTransactions',
	debitTransactions = 'debitTransactions',
}

@Injectable({
	providedIn: 'root',
})
export class StatementsEventsService {
	eventStatementDialog$: Subject<{ statement: StatementEntry; dialog: StatementDialogEnum }> = new Subject<{
		statement: StatementEntry;
		dialog: StatementDialogEnum;
	}>();

	emitOpenStatementDialogEvent(event: { statement: StatementEntry; dialog: StatementDialogEnum }): void {
		this.eventStatementDialog$.next(event);
	}

	getStatementDialogSubscriber(): Observable<{ statement: StatementEntry; dialog: StatementDialogEnum }> {
		return this.eventStatementDialog$.asObservable();
	}
}
