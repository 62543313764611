import { DateTime } from 'luxon';
import { Cadence } from 'src/app/shared/models/cadence.model';
import { ReportTabObjectType } from '../../features/reports/models/report.model';
import { Snack, SnackCarousel } from './snacks.model';
import { AccountTagType } from '@trovata/app/features/transactions/models/tag.model';

export class ItemsTab<T = ListItem> {
	name: string;
	tabId: string;
	editable: boolean;
	snackCarousel: SnackCarousel;
	listItems: T[];
	snacks: Snack[];

	constructor(
		private tabName: string,
		private id: string,
		private canEdit: boolean
	) {
		this.name = tabName;
		this.tabId = id;
		this.editable = canEdit;
	}
}

export class ListItem {
	itemId: string;
	itemType: ReportTabObjectType;
	name: string;
	displayCadence: string;
	isFavorite: boolean;
	createdOn: string;
	createdOnDate: string;
	type: string;
	subtype: string;

	constructor(name: string, cadence: Cadence, date: string, type: string, subType: string, id: string, isFavorite?: boolean) {
		this.name = name;
		this.itemId = id;
		this.displayCadence = cadence ? cadence.toString().substring(0, 1).toUpperCase() + cadence.toString().substring(1) : '';
		this.isFavorite = isFavorite;
		this.type = type;
		this.subtype = this.getSubtypeDisplayValue(subType);
		if (date?.includes(' ')) {
			date = date.replace(' ', 'T');
		}
		this.createdOnDate = date;
		this.createdOn = DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
	}

	private getSubtypeDisplayValue(subtype: string): string {
		switch (subtype) {
			case 'accountId':
				return 'Account';
			case 'institutionId':
				return 'Institution';
			case 'currency':
				return 'Currency';
			case 'entity':
				return AccountTagType.entity;
			case 'region':
				return AccountTagType.region;
			case 'division':
				return AccountTagType.division;
			case 'balances':
				return 'Balances';
			case 'transactions':
				return 'Transactions';
			case 'statementId':
				return 'Statement';
			default:
				return '--';
		}
	}
}
