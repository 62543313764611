<div class="dialog-theme">
	<div class="mt-10 mb-20">
		<div>Statement: {{ options.statement.name }}</div>
		<div>Cadence: {{ options.statementVersion.cadence || 'One-time' }}</div>
		<div>Start date: {{ options.statementVersion.cadenceStartDate }}</div>
		<div>End date: {{ options.statementVersion.cadenceEndDate }}</div>
		<div *ngIf="metricsData" class="mt-10">
			<div>Opening Balance: {{ metricsData.openingBalance }}</div>
			<div>Closing Balance: {{ metricsData.closingBalance }}</div>
			<div>Inflows: {{ metricsData.inflowsAmount }}</div>
			<div>Outflows: {{ metricsData.outflowsAmount }}</div>
		</div>
	</div>
	<ng-container *ngIf="options">
		<statement-version-transactions-table
			[gridMode]="true"
			[statement]="options.statement"
			[statementVersion]="options.statementVersion"
			[statementPreferences]="options.statementPreferences">
		</statement-version-transactions-table>
	</ng-container>
</div>
