<div class="w-100">
	<div class="flex flex-row flex-ai-c w-100 hover-row" [ngClass]="{ 'cell-bottom-border': group.expanded }">
		<div class="title-item alt-child-icon-button" [style.width]="sanitizer.bypassSecurityTrustStyle('calc(' + 100.0 / (dateArray.length + 1) + '% - 36px)')">
			<button
				class="mat-icon-button"
				mat-icon-button
				data-html2canvas-ignore="true"
				matTooltipShowDelay="700"
				(click)="onAltChildrenIconClick(group); $event.stopPropagation()"
				*ngIf="group.altChildren && group.altChildren.length">
				<mat-icon [ngClass]="{ 'trovata-blue-text': group.altChildrenExpanded }">{{ group.altChildrenExpanded ? 'schedule' : 'history_toggle_off' }}</mat-icon>
			</button>
			<button
				class="mat-icon-button"
				mat-icon-button
				data-html2canvas-ignore="true"
				matTooltipShowDelay="700"
				*ngIf="!group.disabledExpand && (!disableExpandProperties || group.children.length > 0)"
				(click)="toggleExpand(); $event.stopPropagation()"
				[style.marginLeft.px]="32 * nestedLevel">
				<mat-icon *ngIf="!group.expanded" class="child-expansion-button">arrow_right</mat-icon>
				<mat-icon *ngIf="group.expanded" class="child-expansion-button">arrow_drop_down</mat-icon>
			</button>
			<ng-content></ng-content>
			<div class="flex-jc-fe">
				<ng-container *ngFor="let marker of group.headerMarkers">
					<span class="header-marker" [class]="marker.class" [matTooltip]="marker.tooltip">
						<mat-icon *ngIf="marker.icon" [class]="marker.iconClass">{{ marker.icon }}</mat-icon>
						<span *ngIf="marker.text" class="marker-text">{{ marker.text }}</span>
					</span>
				</ng-container>
			</div>
		</div>
		<div
			class="header-alignment-area flex cell-right-border cell-left-border"
			[style.width]="sanitizer.bypassSecurityTrustStyle('calc(' + (100.0 - 100.0 / (dateArray.length + 1)) + '% - 56px)')">
			<div #scroller class="viewport" [style.width.px]="width">
				<ng-container *ngFor="let date of dateArray; let idx = index">
					<ng-template [ngTemplateOutlet]="dataCell" [ngTemplateOutletContext]="{ idx: idx, dataAccessor: date, isSummaryCol: false }"></ng-template>
				</ng-container>
				<ng-container *ngIf="summaryColumn">
					<ng-template
						[ngTemplateOutlet]="dataCell"
						[ngTemplateOutletContext]="{ idx: -1, dataAccessor: summaryColumn.dataKey, isSummaryCol: true }"></ng-template>
				</ng-container>
			</div>
		</div>

		<div class="child-menu-container">
			<button
				class="mat-icon-button"
				mat-icon-button
				data-html2canvas-ignore="true"
				matTooltipShowDelay="700"
				[matMenuTriggerFor]="customMenu"
				[disabled]="disableMenus"
				*ngIf="group.customMenuOptions"
				(click)="$event.stopPropagation()">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #customMenu="matMenu" class="custom-menu">
				<ng-template matMenuContent>
					<ng-container *ngFor="let opt of group.customMenuOptions">
						<div [matTooltip]="opt.optionTooltip">
							<button *ngIf="opt.button" mat-menu-item [ngClass]="opt.optionClass" (click)="onMenuItemClicked(opt, group)" [disabled]="opt.disabled">
								<div
									class="flex flex-row flex-ai-c"
									[ngClass]="{ 'flex-jc-sb': (opt.iconRight && opt.iconRight.spaceApart) || (opt.iconLeft && opt.iconLeft.spaceApart) }">
									<ng-template *ngIf="opt.iconLeft" [ngTemplateOutlet]="iconDisplay" [ngTemplateOutletContext]="{ icon: opt.iconLeft }"></ng-template>
									<div>{{ opt.optionText }}</div>
									<ng-template *ngIf="opt.iconRight" [ngTemplateOutlet]="iconDisplay" [ngTemplateOutletContext]="{ icon: opt.iconRight }"></ng-template>
								</div>
							</button>
						</div>
						<mat-divider *ngIf="opt.divider"></mat-divider>
					</ng-container>
				</ng-template>
			</mat-menu>
		</div>
	</div>
	<ng-container *ngIf="group.altChildrenExpanded">
		<div
			*ngFor="let childId of group.altChildren; let idx = index"
			cdkDrag
			[cdkDragPreviewClass]="['cell-bottom-border', 'cell-top-border', 'cell-left-border', 'cell-right-border', 'scrollable-data-table-dragging']"
			[ngClass]="{
				'cell-bottom-border': group.altChildren && idx !== group.altChildren.length - 1,
				'cursor-grab': group.childData.groups[childId].sortable && sortable
			}">
			<app-scrollable-child-group
				#child
				(click)="child.toggleExpand(); $event.stopPropagation()"
				[dateArray]="dateArray"
				[summaryColumn]="summaryColumn"
				[disableMenus]="disableMenus"
				[columnStyles]="columnStyles"
				[group]="group.childData.groups[childId]"
				[scrollLeft]="scrollLeft"
				[clickableHeader]="clickableHeader"
				[itemWidth]="itemWidth"
				[nestedLevel]="nestedLevel + 1"
				[editable]="editable"
				[droppedItem]="dropped"
				(itemClicked)="childClick($event)"
				(sortedGroup)="sortedGroup.emit($event)"
				(customMenuClicked)="customMenuClicked.emit($event)"
				(altChildrenIconClicked)="onAltChildrenIconClick($event)"
				[disableExpandProperties]="disableExpandProperties">
				<div
					[style.marginLeft.px]="32 * (nestedLevel + 1) + 40"
					(click)="
						!group.childData.sortable || !sortable
							? onClick(
									clickableLabel && !group.childData.groups[childId].disableGroupLabelClick && (!group.childData.groups[childId].sortable || !sortable),
									group.childData.groups[childId],
									null,
									null,
									true,
									$event,
									child,
									true
								)
							: ''
					"
					class="child-title-text"
					[ngClass]="{
						'clickable-value-title':
							clickableLabel && !group.childData.groups[childId].disableGroupLabelClick && (!group.childData.groups[childId].sortable || !sortable)
					}">
					{{ group.childData.groups[childId].groupInfo }}
				</div>
			</app-scrollable-child-group>
		</div>
	</ng-container>
</div>

<div [smoothHeight]="group.expanded" [ngClass]="{ 'overflow-visible': group.expanded }" class="child-rows-background">
	<ng-container *ngIf="group.children.length > 0 && group.expanded">
		<div cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListDisabled]="!group.childData.sortable || !sortable">
			<div
				*ngFor="let childId of group.children; let idx = index"
				cdkDrag
				[cdkDragPreviewClass]="['cell-bottom-border', 'cell-top-border', 'cell-left-border', 'cell-right-border', 'scrollable-data-table-dragging']"
				[ngClass]="{
					'cell-bottom-border': group.children && idx !== group.children.length - 1,
					'cursor-grab': group.childData.groups[childId].sortable && sortable
				}">
				<app-scrollable-child-group
					#child
					(click)="child.toggleExpand(); $event.stopPropagation()"
					[dateArray]="dateArray"
					[summaryColumn]="summaryColumn"
					[disableMenus]="disableMenus"
					[columnStyles]="columnStyles"
					[group]="group.childData.groups[childId]"
					[scrollLeft]="scrollLeft"
					[clickableHeader]="clickableHeader"
					[itemWidth]="itemWidth"
					[nestedLevel]="nestedLevel + 1"
					[editable]="editable"
					[droppedItem]="dropped"
					(itemClicked)="childClick($event)"
					(sortedGroup)="sortedGroup.emit($event)"
					(customMenuClicked)="customMenuClicked.emit($event)"
					[disableExpandProperties]="disableExpandProperties">
					<div
						[style.marginLeft.px]="group.childData.groups[childId].children.length > 0 || !disableExpandProperties ? 0 : 32 * nestedLevel + 40"
						(click)="
							!group.childData.sortable || !sortable
								? onClick(
										clickableLabel && !group.childData.groups[childId].disableGroupLabelClick && (!group.childData.groups[childId].sortable || !sortable),
										group.childData.groups[childId],
										null,
										null,
										true,
										$event,
										child,
										true
									)
								: ''
						"
						class="child-title-text"
						[ngClass]="{
							'clickable-value-title':
								clickableLabel && !group.childData.groups[childId].disableGroupLabelClick && (!group.childData.groups[childId].sortable || !sortable)
						}">
						{{ group.childData.groups[childId].groupInfo }}
					</div>
				</app-scrollable-child-group>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="group.children.length === 0 && group.expanded">
		<app-scrollable-property-container
			(click)="$event.stopPropagation()"
			[group]="group"
			[scrollLeft]="scrollLeft"
			[itemWidth]="itemWidth"
			[displayProperties]="group.displayProperties"
			[headerProperties]="group.headerProperties"
			[clickableHeader]="clickableHeader"
			[dateArray]="dateArray"
			[summaryColumn]="summaryColumn"
			[columnStyles]="columnStyles"
			[width]="width"
			[nestedLevel]="nestedLevel + 1"
			[droppedItem]="dropped"
			(itemClicked)="itemClicked.emit($event)"
			[sortable]="sortable"
			(sortedGroup)="childSortChanged($event)">
		</app-scrollable-property-container>
	</ng-container>
</div>
<ng-template #iconDisplay let-icon="icon">
	<mat-icon *ngIf="icon.imageType === 'icon' || !icon.imageType" [class]="'menu-opt-icon ' + icon.class"> {{ icon.icon }}</mat-icon>
	<span *ngIf="icon.imageType === 'outline'" class="material-icons-outlined" [class]="'menu-opt-icon ' + icon.class">{{ icon.icon }}</span>
	<img *ngIf="icon.imageType === 'src'" [class]="'menu-opt-icon ' + icon.class" [src]="icon.icon" />
</ng-template>
<ng-template #dataCell let-idx="idx" let-dataAccessor="dataAccessor" let-isSummaryCol="isSummaryCol">
	<div
		class="flex-item"
		[style.minWidth.px]="itemWidth"
		[ngClass]="{ 'cell-right-border': dateArray && idx >= 0 && (idx !== dateArray.length - 1 || summaryColumn), 'summary-column': isSummaryCol }"
		[style]="columnStyles[dataAccessor]"
		[style]="group.toggleCellStyles ? group.cellStylesByDate[dataAccessor] : {}">
		<div [style]="group.propertyStylesByDate[dataAccessor]" class="value-text">
			<ng-container *ngFor="let headerProperty of group.headerProperties">
				<div
					*ngIf="group.properties[headerProperty]"
					class="value-container flex-item flex flex-row flex-ai-c"
					[style]="group.properties[headerProperty].propertyStylesByDate[dataAccessor]"
					[ngClass]="{
						'cell-marker flex-jc-sb':
							group.properties[headerProperty].cellMarker && (group.properties[headerProperty].data[dataAccessor] || group.placeholderValue),
						'flex-jc-fe': !(group.properties[headerProperty].cellMarker && (group.properties[headerProperty].data[dataAccessor] || group.placeholderValue)),
						'grey-null': group.properties[group.headerProperties[0]].data[dataAccessor] === null,
						'clickable-value': group.properties[headerProperty].clickableValues,
						'red-delta': group.match && group.match[headerProperty] && group.match[headerProperty][dataAccessor] === false
					}"
					(click)="
						onClick(
							group.properties[headerProperty].clickableValues,
							group,
							dataAccessor,
							group.properties[headerProperty].isConvertedValue,
							isSummaryCol,
							$event,
							null,
							null,
							null,
							headerProperty
						)
					">
					<div
						class="cell-marker black-alpha-56"
						*ngIf="group.properties[headerProperty].cellMarker && (group.properties[headerProperty].data[dataAccessor] || group.placeholderValue)">
						{{ group.properties[headerProperty].cellMarker }}
					</div>
					{{ group.properties[headerProperty].data[dataAccessor] || group.placeholderValue }}
				</div>
			</ng-container>
		</div>
	</div>
</ng-template>
