import { Injectable, Injector, ApplicationRef, ComponentRef, createComponent } from '@angular/core';
import { TABS, TAB_DATA } from '../models/popout-token.model';
import { TransactionsGridDialogComponent } from '../components/transactions-grid-dialog/transactions-grid-dialog.component';
import { StatementTransactionsPopoutData, TransactionPopoutData, TransactionsDialogData, TrovataAIPopoutData } from '../models/popout-data.model';
import { StatementTransactionsPopoutDialogComponent } from 'src/app/features/statements/components/statement-transactions-popout-dialog/statement-transactions-popout-dialog.component';

@Injectable({
	providedIn: 'root',
})
export class PopoutService {
	styleSheetElement: any;

	constructor(
		private injector: Injector,
		private applicationRef: ApplicationRef
	) {}

	openOnce(url, target) {
		// open a blank "target" window
		// or get the reference to the existing "target" window
		const winRef = (window as any).open(url, target, '', true);
		// if the "target" window was just opened, change its url
		if (winRef.location.href === 'about:blank') {
			winRef.location.href = url;
		}
		return winRef;
	}

	openTransactionsPopoutModal(dialogData: TransactionsDialogData): void {
		if (!this.isPopoutWindowOpen()) {
			this.openPopoutWindow(dialogData);
			return;
		}

		const isTrovataAIDialog: boolean = (<TrovataAIPopoutData>dialogData).openTrovataAIGrid;
		const isStatementDialog: boolean = (<StatementTransactionsPopoutData>dialogData).openStatementTransactionPopout;
		const transactionsPopoutData: TransactionPopoutData = <TransactionPopoutData>dialogData;

		const isSameTransactionSearch: boolean =
			TABS['componentInstance'].data['searchData'] === transactionsPopoutData.searchData &&
			TABS['componentInstance'].data['sortFilter'] === transactionsPopoutData.sortFilter &&
			TABS['componentInstance'].data['positionType'] === transactionsPopoutData.positionType;
		// When tab is open and there is change in data, focus on popout modal reattach compnonent with new data and focus on new tab
		if (!isSameTransactionSearch || isTrovataAIDialog || isStatementDialog) {
			this.reAttachComponent(dialogData);
		}
		this.focusPopoutWindow();
	}

	private reAttachComponent(dialogData): void {
		dialogData.currentWindow = TABS['windowInstance'].window;
		dialogData.currentDocument = TABS['windowInstance'].document;
		const injector: Injector = this.createInjector(dialogData);
		const component = dialogData?.openStatementTransactionPopout ? StatementTransactionsPopoutDialogComponent : TransactionsGridDialogComponent;
		const componentInstance: TransactionsGridDialogComponent = this.attachTransactionsGridComponent(injector, TABS['windowInstance'], component);
		TABS['componentInstance'] = componentInstance;
	}

	private openPopoutWindow(data: TransactionsDialogData): void {
		const windowInstance: object = this.openOnce(data.path, data.tabTitle);

		// Wait for window instance to be created
		setTimeout(() => {
			// passes in the document of the new window instance to be used for copying
			TABS['windowInstance'] = windowInstance;
			data.currentWindow = TABS['windowInstance'].window;
			data.currentDocument = TABS['windowInstance'].document;
			this.createCDKPortal(data, windowInstance);
		}, 1000);
	}

	private createInjector(data): Injector {
		return Injector.create({ parent: this.injector, providers: [{ provide: TAB_DATA, useValue: data }] });
	}

	private createCDKPortal(data, windowInstance) {
		if (windowInstance) {
			// Copy stylesheet link from parent window
			this.styleSheetElement = this.getStyleSheetElement();
			windowInstance.document.head.appendChild(this.styleSheetElement);

			this.styleSheetElement.onload = () => {
				// Copy styles from parent window
				document.querySelectorAll('style').forEach(htmlElement => {
					windowInstance.document.head.appendChild(htmlElement.cloneNode(true));
				});

				const injector = this.createInjector(data);
				windowInstance.document.title = data['tabTitle'];
				const component = data?.openStatementTransactionPopout ? StatementTransactionsPopoutDialogComponent : TransactionsGridDialogComponent;
				setTimeout(() => {
					const componentInstance = this.attachTransactionsGridComponent(injector, windowInstance, component);
					TABS['componentInstance'] = componentInstance;
				}, 1000);
			};
		}
	}

	private isPopoutWindowOpen() {
		return TABS['windowInstance'] && !TABS['windowInstance'].closed;
	}

	private focusPopoutWindow() {
		TABS['windowInstance'].focus();
	}

	closePopoutModal() {
		Object.keys(TABS).forEach(() => {
			if (TABS['windowInstance']) {
				TABS['windowInstance'].close();
			}
		});
	}

	private attachTransactionsGridComponent(injector: Injector, windowInstance, component) {
		const componentRef: ComponentRef<typeof component> = createComponent(component, {
			environmentInjector: this.applicationRef.injector,
			hostElement: windowInstance.document.body,
			elementInjector: injector,
		});

		this.applicationRef.attachView(componentRef.hostView);
		return componentRef.instance;
	}

	private getStyleSheetElement() {
		const styleSheetElement = document.createElement('link');
		document.querySelectorAll('link').forEach(htmlElement => {
			if (htmlElement.rel === 'stylesheet') {
				const absoluteUrl = new URL(htmlElement.href).href;
				styleSheetElement.rel = 'stylesheet';
				styleSheetElement.href = absoluteUrl;
			}
		});
		return styleSheetElement;
	}
}
