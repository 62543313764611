import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { RequestThrottler } from '../../reports/utils/request-throttle';
import { Observable, throwError } from 'rxjs';
import {
	StatementEntry,
	StatementResponse,
	StatementsResponse,
	StatementVersionBalancesResponse,
	StatementVersionExportResponse,
	StatementVersionsResponse,
	StatementVersionTransactionsResponse,
} from 'src/app/features/statements/models/statements.models';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class StatementsService extends RequestThrottler {
	constructor(public httpClient: HttpClient) {
		super(50);
	}

	public getStatements(): Observable<HttpResponse<StatementsResponse>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/statements/list`;
		return this.httpClient.post<StatementsResponse>(
			url,
			{},
			{
				observe: 'response',
			}
		);
	}

	public getFullStatementVersion(statementId: string): Observable<HttpResponse<StatementResponse>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/statements/${statementId}`;
		return this.httpClient.post<StatementResponse>(
			url,
			{},
			{
				observe: 'response',
			}
		);
	}

	public getStatementVersions(statementId: string): Observable<HttpResponse<StatementVersionsResponse>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/statements/${statementId}/versions`;
		return this.httpClient.post<StatementVersionsResponse>(
			url,
			{},
			{
				observe: 'response',
			}
		);
	}

	public getStatementVersionBalances(statementId: string, versionId: string, requestBody: object): Observable<HttpResponse<StatementVersionBalancesResponse>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/statements/${statementId}/versions/${versionId}/balances`;
		return this.httpClient.post<StatementVersionBalancesResponse>(url, requestBody, {
			observe: 'response',
		});
	}

	public getStatementVersionTransactions(
		statementId: string,
		versionId: string,
		requestBody: object
	): Observable<HttpResponse<StatementVersionTransactionsResponse>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/statements/${statementId}/versions/${versionId}/transactions`;
		return this.httpClient.post<StatementVersionTransactionsResponse>(url, requestBody, {
			observe: 'response',
		});
	}

	public deleteStatement(statementId: string): Observable<HttpResponse<void>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/statements/${statementId}`;
		return this.httpClient
			.delete<void>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	public createStatement(statementPayload: StatementEntry): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/statements`;
		return this.httpClient.post(url, statementPayload, {
			observe: 'response',
		});
	}

	public patchStatement(statementId: string, statementPayload: object): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/statements/${statementId}`;
		return this.httpClient.patch(url, statementPayload, {
			observe: 'response',
		});
	}

	public patchStatementVersion(statementId: string, versionId: string, statementVersionPayload: object): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/statements/${statementId}/versions/${versionId}`;
		return this.httpClient.patch(url, statementVersionPayload, {
			observe: 'response',
		});
	}

	public getStatementVersionExportUrl(statementId: string, versionId: string): Observable<HttpResponse<StatementVersionExportResponse>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/statements/${statementId}/versions/${versionId}/export`;
		return this.httpClient.get<StatementVersionExportResponse>(url, {
			observe: 'response',
		});
	}
}
