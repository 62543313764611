import { Component, Inject, EventEmitter, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TQLFieldContext, TQLValuesDict } from 'src/app/shared/models/tql.model';
import { AccountTargetV3 } from 'src/app/shared/models/account-target.model';
import { Currency, CurrencyDict } from 'src/app/shared/models/currency.model';
import { PermissionMap } from 'src/app/features/settings/models/feature.model';
import { CustomerFeatureState } from 'src/app/features/settings/store/state/customer-feature.state';
import { Select } from '@ngxs/store';
import { Entity } from 'src/app/features/entities/models/entity.model';
import { metricsDialogTitle } from 'src/app/features/statements/models/statements.models';
import { StatementDialogEnum } from 'src/app/features/statements/services/statements-events.service';
import { TAB_DATA } from 'src/app/features/transactions/models/popout-token.model';
import { StatementMetricsData } from 'src/app/features/statements/models/statement-metrics-data.model';

@Component({
	selector: 'trovata-statement-transactions-popout-dialog',
	templateUrl: './statement-transactions-popout-dialog.component.html',
	styleUrls: ['./statement-transactions-popout-dialog.component.scss'],
})
export class StatementTransactionsPopoutDialogComponent {
	destroyed$: Subject<boolean>;
	searchDisabled: boolean;
	apiInFlight: boolean;
	dialogTitle: string;

	options: any;
	metricsData: StatementMetricsData;
	accounts: AccountTargetV3[];
	convertedCurrency: Currency;
	entities: Entity[];
	currencyDict: CurrencyDict;
	@Select(CustomerFeatureState.permissionIds)
	userAvailablePermissions$: Observable<PermissionMap>;
	userAvailablePermissions: PermissionMap;

	tqlJSONExpression: object;
	StatementDialogEnum: typeof StatementDialogEnum;
	tqlContext: TQLFieldContext;
	tqlValuesDict: TQLValuesDict;

	@Output() submitTQLSelection: EventEmitter<object>;

	constructor(@Inject(TAB_DATA) public data: any) {
		this.options = data;
		this.searchDisabled = true;
		this.StatementDialogEnum = StatementDialogEnum;
		this.destroyed$ = new Subject<boolean>();
		this.dialogTitle = metricsDialogTitle(this.options.type);
		this.metricsData = new StatementMetricsData(data.statement, data.statementVersion, data.currencyDict);
	}
}
