export interface TableControlsMenuRow {
	title: string;
	columnKey?: string;
	description?: string;
	checked: boolean;
	onToggle: (state: boolean) => void;
	onRename?: (title: string) => void;
	originalName?: () => string;
}

export interface CustomToggleIcons {
	on: string;
	off: string;
}

export interface TableControlsMenuSection {
	title?: string;
	gridModeOnly?: boolean;
	customToggleIcons?: CustomToggleIcons;
	rows: TableControlsMenuRow[];
	onReorder?: (previousIndex: number, currentIndex: number) => void;
}

export enum TableControlsConstants {
	gridModeOnly = 'Grid mode only',
	currency = 'Currency',
	convertedCurrency = 'Converted Currency',
	currencySymbols = 'Currency Symbols ($,€, £, ¥, etc.)',
	currencySymbolsDescription = 'Symbols in "Amount" column',
}
