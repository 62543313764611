import {
	ColumnType,
	ExpandableRow,
	PaginatedTableColumn,
	PaginatedTableMatChip,
	PaginatedTableViewModel,
	RowExpansionType,
} from '../../../shared/models/paginated-table-view-model';
import { ParameterType } from '../../../shared/models/search-parameter.model';
import { CurrencySummary, Transaction, TransactionsSummary } from './transaction.model';
import { Currency, CurrencyDict } from '../../../shared/models/currency.model';
import { DateTime } from 'luxon';
import { Formatter } from 'src/app/shared/utils/formatter';
import { TitleCasePipe } from '@angular/common';
import { Tag } from './tag.model';

export const accountGroupAutoCompleteTypes: ParameterType[] = [
	ParameterType.currency,
	ParameterType.entity,
	ParameterType.division,
	ParameterType.region,
	ParameterType.institutionId,
	ParameterType.accountId,
];

export enum TransactionColumns {
	selected = 'selected',
	date = 'date',
	description = 'description',
	institutionName = 'institution',
	accountNumber = 'account',
	currency = 'currency',
	amount = 'amount',
	amountConverted = 'amountConverted',
	status = 'status',
	overlapWarning = 'overlapWarning',
	runningBalanceConverted = 'runningBalanceConverted',
}

export const accountTransactionPagColumns: PaginatedTableColumn[] = [
	new PaginatedTableColumn(TransactionColumns.date, ColumnType.string, { header: 'Date', sortable: true, templateFields: { textAlign: 'left' } }),
	new PaginatedTableColumn(TransactionColumns.description, ColumnType.string, {
		header: 'Description',
		templateFields: {
			textAlign: 'left',
			tooltipBinding: 'description',
		},
	}),
	new PaginatedTableColumn(TransactionColumns.institutionName, ColumnType.string, {
		header: 'Bank',
		sortable: true,
		templateFields: {
			textAlign: 'left',
			tooltipBinding: 'institutionName',
			displayBinding: 'institutionName',
		},
	}),
	new PaginatedTableColumn(TransactionColumns.currency, ColumnType.string, { header: 'Currency', templateFields: { textAlign: 'left' } }),
	new PaginatedTableColumn(TransactionColumns.amount, ColumnType.string, {
		header: 'Amount',
		sortable: true,
		templateFields: {
			textAlign: 'right',
			detailedHeader: 'Balance',
			displayBinding: 'amountDisplay',
		},
	}),
	new PaginatedTableColumn(TransactionColumns.amountConverted, ColumnType.string, {
		header: 'Amount (USD Eq.)',
		sortable: true,
		templateFields: {
			textAlign: 'right',
			detailedHeader: 'Converted Balance',
			displayBinding: 'amountConvertedDisplay',
		},
	}),
];

export const transactionPagColumns: PaginatedTableColumn[] = [
	new PaginatedTableColumn(TransactionColumns.overlapWarning, ColumnType.matChips, {
		included: false,
		templateFields: { displayBinding: 'overlappingTagsChips' },
	}),
	new PaginatedTableColumn(TransactionColumns.date, ColumnType.string, {
		header: 'Date',
		sortable: true,
		templateFields: {
			textAlign: 'left',
			cellClassBinding: 'cellClass',
		},
	}),
	new PaginatedTableColumn(TransactionColumns.description, ColumnType.string, {
		header: 'Description',
		templateFields: {
			textAlign: 'left',
			tooltipBinding: 'description',
		},
	}),
	new PaginatedTableColumn(TransactionColumns.institutionName, ColumnType.string, {
		header: 'Bank',
		sortable: true,
		templateFields: {
			textAlign: 'left',
			tooltipBinding: 'institutionName',
			displayBinding: 'institutionName',
		},
	}),
	new PaginatedTableColumn(TransactionColumns.accountNumber, ColumnType.string, {
		header: 'Account Number',
		sortable: true,
		templateFields: {
			textAlign: 'left',
			tooltipBinding: 'accountNumber',
			displayBinding: 'accountNumber',
		},
	}),
	new PaginatedTableColumn(TransactionColumns.currency, ColumnType.string, { header: 'Currency', templateFields: { textAlign: 'left' } }),
	new PaginatedTableColumn(TransactionColumns.amount, ColumnType.string, {
		header: 'Amount',
		sortable: true,
		templateFields: {
			textAlign: 'right',
			detailedHeader: 'Balance',
			displayBinding: 'amountDisplay',
			cellClassBinding: 'cellClass',
		},
	}),
	new PaginatedTableColumn(TransactionColumns.amountConverted, ColumnType.string, {
		header: 'Amount (USD Eq.)',
		sortable: true,
		templateFields: {
			textAlign: 'right',
			detailedHeader: 'Converted Balance',
			displayBinding: 'amountConvertedDisplay',
			cellClassBinding: 'cellClass',
		},
	}),
	new PaginatedTableColumn(TransactionColumns.status, ColumnType.matChips, {
		header: 'Status',
		sortable: true,
		templateFields: { displayBinding: 'statusChip' },
	}),
	new PaginatedTableColumn(TransactionColumns.runningBalanceConverted, ColumnType.string, {
		header: 'Balance (USD Eq.)',
		sortable: false,
		included: false,
		templateFields: {
			textAlign: 'right',
			detailedHeader: 'Converted Running Balance',
			displayBinding: 'runningBalanceConvertedDisplay',
			cellClassBinding: 'cellClass',
		},
	}),
];

export const transactionSearchDialogColumns: PaginatedTableColumn[] = [
	new PaginatedTableColumn(TransactionColumns.description, ColumnType.string, { header: 'Description', templateFields: { textAlign: 'left' } }),
	new PaginatedTableColumn(TransactionColumns.amount, ColumnType.currency, {
		header: 'Amount',
		sortable: true,
		templateFields: {
			textAlign: 'right',
			currencyPath: 'currencyObj',
			roundingPath: '',
		},
	}),
];

export class PaginatedTransactionRow implements ExpandableRow {
	transaction: Transaction;
	date: string;
	time: string;
	description: string;
	institutionName: string;
	accountNumber: string;
	amount: number;
	amountDisplay: string;
	amountConverted: number;
	amountConvertedDisplay: string;
	runningBalanceConvertedDisplay: string;
	transactionId: string;
	currency: string;
	status: string;
	currencyObj: Currency;
	currencyDict: CurrencyDict;
	clickable: boolean;
	expanded: boolean;
	statusChip: PaginatedTableMatChip[];
	cellClass: string;
	selected: boolean;
	overlappingTagsChips: PaginatedTableMatChip[];
	overlappingTagsCount: number;
	tql: object;

	expandedDisplay: TransactionExpandedDetail[];

	constructor(transaction: Transaction, currencyDict: CurrencyDict, clickable: boolean, selected?: boolean, tql?: object) {
		this.tql = tql;
		this.expanded = false;
		this.transaction = transaction;
		this.currency = transaction.currency;
		this.currencyObj = currencyDict[this.currency];
		const formatter: Formatter = new Formatter();
		this.clickable = clickable;
		this.selected = selected;
		this.expandedDisplay = this.transaction.metadataDisplay;
		this.date = DateTime.fromISO(transaction.date.toString(), {
			zone: 'UTC',
		}).toFormat('yyyy-MM-dd');
		this.time = DateTime.fromISO(transaction.date.toString(), {
			zone: 'UTC',
		}).toFormat('t ZZZZ');
		this.description = transaction.description;
		this.accountNumber = transaction.account.accountNumber;
		this.institutionName = transaction.account.institutionNickname;
		this.amount = (this.transaction.type === 'DEBIT' ? -1 : 1) * transaction.amount;
		this.amountDisplay = formatter.formatValue(this.amount, currencyDict[this.currency]);
		this.amountConverted = (this.transaction.type === 'DEBIT' ? -1 : 1) * transaction.amountConverted;
		this.amountConvertedDisplay = formatter.formatValue(this.amountConverted, currencyDict[transaction.currencyConverted]);
		this.runningBalanceConvertedDisplay = transaction.runningBalanceConverted
			? formatter.formatValue(transaction.runningBalanceConverted, currencyDict[transaction.currencyConverted])
			: '-';
		this.transactionId = transaction.transactionId;
		if (transaction.status) {
			const titlecase: TitleCasePipe = new TitleCasePipe();
			this.status = transaction.status;
			this.cellClass = transaction.status.toLowerCase() === 'pending' ? 'italic' : '';
			this.statusChip = [
				{
					text: titlecase.transform(transaction.status),
					class: transaction.status.toLowerCase(),
					tooltip: transaction.status.toLowerCase() === 'pending' ? 'Pending transactions do not affect account balance' : '',
				},
			];
		}
		this.setOverlappingTagsChips();

		// create Details column for expanded row
		const expandedDetail: TransactionExpandedDetail = new TransactionExpandedDetail();
		expandedDetail.blockType = 'Details';
		expandedDetail.blockValues = [];
		const expandedDetailType: BlockValue = new BlockValue('Type', this.transaction.type);
		const expandedDetailCategory: BlockValue = new BlockValue('Category', this.transaction.category);
		const expandedDetailTransactedCurrency: BlockValue = new BlockValue('Transacted Currency', this.transaction.currency);
		const expandedDetailTransactedAmount: BlockValue = new BlockValue('Transacted Amount', this.amount.toString());
		const expandedDetailDescription: BlockValue = new BlockValue('Description', this.transaction.description);
		const expandedDetailBankReference: BlockValue = new BlockValue('Bank Reference', this.transaction.bankReference);
		const expandedDetailStatus: BlockValue = new BlockValue('Status', this.transaction.status);
		expandedDetail.blockValues.push(
			expandedDetailType,
			expandedDetailCategory,
			expandedDetailTransactedCurrency,
			expandedDetailTransactedAmount,
			expandedDetailDescription,
			expandedDetailBankReference,
			expandedDetailStatus
		);
		this.expandedDisplay.unshift(expandedDetail);
	}

	updateTransactionTags(tags: Tag[], overlappingTags?: string[]): void {
		this.transaction.tags = tags;
		if (overlappingTags?.length > 1) {
			this.transaction.overlappedTags = overlappingTags;
		} else {
			this.transaction.overlappedTags = [];
		}
		this.setOverlappingTagsChips();
	}

	private setOverlappingTagsChips(): void {
		const overlappingTagsCount: number = this.transaction.overlappedTags?.length;
		if (overlappingTagsCount > 1) {
			this.overlappingTagsChips = [
				{
					text: overlappingTagsCount.toString(),
					class: 'warning-counter-badge',
				},
			];
		} else {
			this.overlappingTagsChips = [];
		}
	}
}
export class PaginatedTransactionTableViewModel {
	table: PaginatedTableViewModel<PaginatedTransactionRow>;
	total: number;
	formattedTotal: string;
	currency: Currency;
	currencyConverted: Currency;
	transactionRows: PaginatedTransactionRow[];
	currencyBreakdown: CurrencySummaryDisplay[];
	length: number;
	formatter: Formatter;

	constructor(
		transactions: Transaction[],
		private currencyDict: CurrencyDict,
		private clickable: boolean = false,
		private selectionMode: boolean,
		transactionsSummary: TransactionsSummary,
		private pageSize: number,
		expansionType?: RowExpansionType,
		tagEditingEnabled?: boolean,
		allSelected?: boolean,
		tql?: object
	) {
		this.formatter = new Formatter();
		this.transactionRows = transactions.map(transaction => new PaginatedTransactionRow(transaction, this.currencyDict, this.clickable, allSelected, tql));
		this.table = new PaginatedTableViewModel<PaginatedTransactionRow>(
			'transactionId',
			this.transactionRows,
			this.selectionMode,
			null,
			null,
			'No Data to Display',
			expansionType || RowExpansionType.transactionDetail,
			false,
			transactionsSummary?.totalTransactions,
			null, // radioSelectionMode
			false, // hideHeaderRow
			tagEditingEnabled // tagEditingEnabled
		);
		this.table.pageSize = this.pageSize;
		this.table.useCustomPagination = true;
		if (transactionsSummary) {
			this.currencyBreakdown = [];
			this.length = transactionsSummary.totalTransactions;
			this.table.pageSize = this.pageSize;
			this.total = transactionsSummary.currencySummary.converted;
			this.currencyConverted = this.currencyDict[transactionsSummary.currencySummary.currencyConverted];
			this.formattedTotal = this.formatter.formatValue(this.total || '', this.currencyDict[transactionsSummary.currencySummary.currencyConverted]);
			const currencySummary: CurrencySummary = transactionsSummary.currencySummary;
			this.currencyBreakdown = Object.keys(currencySummary)
				.filter((currencyCode: string) => currencyCode !== 'converted' && currencyCode !== 'currencyConverted')
				.map(key => {
					const summaryCurrency: Currency = this.currencyDict[key];
					const summaryAmount: number = currencySummary[key];
					const summaryAmountFormatted: string = this.formatter.formatValue(summaryAmount, summaryCurrency);
					const currencySummaryDisplay: CurrencySummaryDisplay = new CurrencySummaryDisplay(summaryAmount, summaryCurrency, summaryAmountFormatted);
					return currencySummaryDisplay;
				});
		}
	}
}

export class CurrencySummaryDisplay {
	amount: number;
	amountFormatted: string;
	currency: Currency;

	constructor(amount: number, currency: Currency, amountFormatted?: string) {
		this.amount = amount;
		this.currency = currency;
		this.amountFormatted = amountFormatted;
	}
}
export class TransactionExpandedDetail {
	blockType: string;
	blockValues: BlockValue[];
}

export class BlockValue {
	displayKey: string;
	displayValue: string;

	constructor(key: string, value: string) {
		this.displayKey = key;
		this.displayValue = value;
	}
}
